import OptionsSelector from '@components/options-selector';
import { observer } from 'mobx-react-lite';

import { Column } from 'components/Column';
import { OptionsList } from 'components/OptionsList';
import withCarTypes from 'hocs/withCarTypes';
import { useStore } from 'hooks/useStore';
import { TypeSelector } from 'widgets/TypeSelector';

import { Mobile } from './Mobile';

const WrappedOptionsSelector = withCarTypes(OptionsSelector);
const WrappedOptionList = withCarTypes(OptionsList);

const carTypesShortListIds = ['91', '1', '844424930131980', '4', '70368744191104'];

interface CarTypesProps {
  className?: string;
}

const CarTypes = (props: CarTypesProps) => {
  const {
    dictionaries,
    dictionaries: { data },
    app: { i18n },
    ui: { isAdvancedFiltersVisible, toggleIsAdvancedFiltersVisible },
    filter: {
      truckType: { setTruckTypesFromBitSum, truckTypes, clearTruckTypes, isDefault, addParentCarTypes },
    },
  } = useStore();

  const handleChange = (_values: string[], bitsum: string) => {
    setTruckTypesFromBitSum(bitsum);
  };

  const selectedValues = addParentCarTypes(dictionaries.carTypesWithChildren, truckTypes);

  return (
    <Column
      className={props.className}
      header={i18n.carTypes.label}
      isContentVisible={isAdvancedFiltersVisible}
      isHighlighted={!isDefault}
      onClick={toggleIsAdvancedFiltersVisible}
    >
      <TypeSelector
        OptionsList={WrappedOptionList}
        OptionsSelector={WrappedOptionsSelector}
        dictionary={data.carTypes}
        selectedCount={truckTypes.length}
        selectedValues={selectedValues}
        shortListIds={carTypesShortListIds}
        onChange={handleChange}
        onClear={clearTruckTypes}
      />
    </Column>
  );
};

CarTypes.Mobile = Mobile;

const connected = observer(CarTypes);

export { connected as CarTypes };
