import Autosuggest from '@components/autosuggest';
import Icon from '@components/icon';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { Checkbox } from 'components/Checkbox';
import { Field } from 'components/Field';
import { QuestionTooltip } from 'components/QuestionTooltip';
import { RestrictedInput } from 'components/RestrictedInput';
import { geoSuggestionTypes } from 'constants/geo';
import { inputRestriction } from 'constants/inputRestrictions';
import { useStore } from 'hooks/useStore';
import { Geo as GeoInstance } from 'store/FilterStore/Geo';
import { LocalityType } from 'types/cargosApp/Geo';
import { GeoList } from 'widgets/Geo/GeoList';

import css from './Geo.module.css';

interface GeoProps {
  label: string;
  placeholder: string;
  exactSearch: {
    desc: {
      firstPart: string;
      secondPart: string;
    };
    tip: string;
  };
  point: GeoInstance;
  dataQa?: string;
}

const Geo = (props: GeoProps) => {
  const {
    app: { i18n, locale },
    filter,
  } = useStore();

  const { label, placeholder, exactSearch, point, dataQa } = props;

  const handleLocalityChange = (value: string) => {
    point.locality.setValue(value);
    point.locality.resetSuggestion();
    point.resetList();
  };

  const isRadiusDisabled = !point.isCityLocality || filter.routeParams.isEllipseActive;

  const renderSuggestion = (suggestion: LocalityType) => {
    const isList = suggestion.type === geoSuggestionTypes.list;
    const afterText = isList ? `, ${i18n.route.suggest.geoList}` : '';

    return (
      <div key={`${suggestion.text}-${label}`} className={css.suggestion}>
        {isList && <Icon className={css.listIcon} color="currentColor" height={12} name="ic_list" width={12} />}
        <span>
          {suggestion.text}
          {afterText}
        </span>
      </div>
    );
  };

  return (
    <div>
      <div className={css.topRow}>
        <Field labelBold stretchContent containerClassName={css.field} labelClassName={css.label} labelText={label}>
          <Autosuggest
            classNames={{
              dropdown: css.dropdown,
              label: css.autosuggest,
            }}
            dataQa={dataQa}
            error={point.error}
            locale={locale}
            placeholder={placeholder}
            renderSuggestion={renderSuggestion}
            suggestions={point.locality.suggestions}
            value={point.locality.value}
            onChange={handleLocalityChange}
            onClear={point.locality.clear}
            onSuggestionSelected={point.locality.onSuggestionSelected}
            onSuggestionsClearRequested={point.locality.resetSuggestions}
            onSuggestionsFetchRequested={point.locality.onFetchRequested}
          />
        </Field>

        <Field
          contentClassName="glz-tooltip glz-is-position-top"
          contentWidth={72}
          labelClassName={css.radiusLabel}
          labelContent={<QuestionTooltip className={css.radiusLabelQuestion} text={i18n.route.radius.descTip} />}
          labelText={i18n.common.radius}
        >
          <RestrictedInput
            className={css.input}
            customRestrict={['leadingZero', 'floatNumbers', 'leadingSymbols']}
            disabled={isRadiusDisabled}
            inputRestrictionType={inputRestriction.nonNegativeFloatDecimalLimit}
            maxLength={3}
            placeholder={i18n.common.km}
            value={point.radius.view}
            onValueChange={point.radius.setValueFromInput}
          />

          {isRadiusDisabled && (
            <span className={cn('glz-tooltiptext', css.tooltip)}>
              {filter.routeParams.isEllipseActive ? i18n.route.radius.disabledByEllipse : i18n.route.radius.disabledTip}
            </span>
          )}
        </Field>
      </div>

      <div className={css.bottomRow}>
        <GeoList point={point} />

        <Checkbox small checked={point.isExact} disabled={point.isExactDisabled} onChange={point.toggleExact}>
          <span>
            <span className={css.hidablePart}>{exactSearch.desc.firstPart}</span> {exactSearch.desc.secondPart}
          </span>
          <QuestionTooltip text={exactSearch.tip} />
        </Checkbox>
      </div>
    </div>
  );
};

const connected = observer(Geo);

export { connected as Geo };
