import cn from 'clsx';
import React from 'react';

import { QuestionTooltip } from 'components/QuestionTooltip';
import { MetricKeys, events } from 'constants/metrics';
import { staticTabsId, tabIds } from 'constants/ui';
import { sendDataLakeEvent, sendMetricsEvent } from 'utils/metrics';
import { scrollTo } from 'utils/nativeDOM';

import { TabProps } from './types';

import css from './Tab.module.css';

const Tab = (props: TabProps) => {
  const { tab, className, isActive, children, onChange, onDisabledClick, isSpecial, isSticky } = props;

  const handleClick = (event: React.MouseEvent) => {
    if (tab.disabled) {
      event.stopPropagation();
      event.preventDefault();

      onDisabledClick();

      return;
    }
    if (tab.id === tabIds.chains) {
      sendMetricsEvent(events.loadsChainsClick);
    }
    if (tab.href) return;

    onChange(tab.id);

    if (isSticky) scrollTo(`#${staticTabsId}`, { block: 'start' });

    if (!isActive) {
      const target = `${tab.id}Open` as MetricKeys;

      if (events[target]) {
        sendDataLakeEvent(events[target]);
      }
    }
  };

  return (
    <a
      className={cn(css.tab, className, {
        [css.tab_active]: isActive,
        [css.tab_special]: !tab.disabled && isSpecial,
      })}
      href={tab.href}
      role="button"
      onClick={handleClick}
    >
      {children}

      {!tab.disabled && tab.hintTooltip && <QuestionTooltip size={16} text={tab.hintTooltip} />}
    </a>
  );
};

export { Tab };
