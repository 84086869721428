import { ReactNode } from 'react';

import css from './Header.module.css';

interface IHeader {
  children: ReactNode;
}

const Header = (props: IHeader) => {
  return <div className={css.container}>{props.children}</div>;
};

export { Header };
