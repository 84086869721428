import { observer } from 'mobx-react-lite';

import { Field } from 'components/Field';
import { MinMax } from 'components/MinMax/MinMax';
import { useStore } from 'hooks/useStore';

import css from './Volume.module.css';

const Volume = () => {
  const {
    app: { i18n },
    filter: { volume },
  } = useStore();

  const labelText = `${i18n.volume.label}, ${i18n.common.meter}`;

  return (
    <Field containerClassName={css.volume} labelContent={<sup className={css.sup}>3</sup>} labelText={labelText}>
      <MinMax
        className={css.volumeInputs}
        max={volume.max.view}
        min={volume.min.view}
        onBlur={volume.handleSwap}
        onMaxChange={volume.max.setValueFromInput}
        onMinChange={volume.min.setValueFromInput}
      />
    </Field>
  );
};

const connected = observer(Volume);

export { connected as Volume };
