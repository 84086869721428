const IconCargoSearchHistory = () => (
  <svg fill="none" height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M21.4694 18.3754V11.8126H14.7699C14.5297 11.8126 14.3092 11.9425 14.195 12.1525L10.7669 18.3754H21.4694ZM22.782 18.3754H33.4838L30.0557 12.1525C29.9415 11.9425 29.721 11.8126 29.4808 11.8126H22.782V18.3754ZM22.1341 10.5001H29.4821C30.2001 10.5001 30.8616 10.8899 31.2068 11.5186L35.1693 18.7139C35.231 18.8253 35.2569 18.9468 35.2507 19.065V24.2818C35.2507 24.6441 34.9567 24.9381 34.5944 24.9381C34.2322 24.9381 33.9382 24.6441 33.9382 24.2818V19.6879H10.3125V35.0105C10.3125 35.2467 10.5042 35.4384 10.7404 35.4384H22.7816C23.1439 35.4384 23.4379 35.7324 23.4379 36.0946C23.4379 36.4569 23.1439 36.7509 22.7816 36.7509H10.7404C9.78096 36.7509 9 35.9699 9 35.0105V19.0317L9 19.031C8.99994 18.9615 9.01096 18.8914 9.0338 18.8233C9.0474 18.7827 9.06486 18.7439 9.08575 18.7072L13.0452 11.5199C13.3904 10.8899 14.0519 10.5001 14.7699 10.5001H22.1172L22.1257 10.5L22.1341 10.5001ZM31.3131 39.3757C27.6944 39.3757 24.7504 36.4317 24.7504 32.8131C24.7504 29.1944 27.6944 26.2504 31.3131 26.2504C34.9317 26.2504 37.8757 29.1944 37.8757 32.8131C37.8757 34.3868 37.3189 35.8329 36.392 36.9648L40.3083 40.8811C40.5642 41.1371 40.5642 41.5531 40.3083 41.8091C40.181 41.9364 40.013 42.0007 39.845 42.0007C39.6769 42.0007 39.5089 41.9364 39.3803 41.8091L35.4639 37.8927C34.3322 38.8192 32.8864 39.3757 31.3131 39.3757ZM31.3131 27.5629C28.4176 27.5629 26.0629 29.9176 26.0629 32.8131C26.0629 35.7085 28.4176 38.0632 31.3131 38.0632C34.2085 38.0632 36.5632 35.7085 36.5632 32.8131C36.5632 29.9176 34.2085 27.5629 31.3131 27.5629Z"
      fill="#8C969D"
      fillRule="evenodd"
    />
  </svg>
);

export { IconCargoSearchHistory };
