import cn from 'clsx';

import { useStore } from 'hooks/useStore';
import { Geo } from 'store/FilterStore/Geo';
import { PopupIds } from 'store/UIStore/Popups';

const CreateAction = ({ point }: { point: Geo }) => {
  const {
    app: { i18n },
    ui: { popups },
    profile,
  } = useStore();

  const isCreateNewListDisabled = !profile.data?.isUser;

  const handleCreateNewList = () => {
    if (isCreateNewListDisabled) return;

    popups.open(PopupIds.geoListsPopup, {
      point,
    });
  };

  return (
    <button
      className={cn('glz-tooltip', 'glz-is-position-top', 'glz-link', 'glz-is-dashed', {
        'glz-is-disabled': isCreateNewListDisabled,
      })}
      onClick={handleCreateNewList}
    >
      {i18n.route.geoList.createNew}
      {isCreateNewListDisabled && (
        <span className="glz-tooltiptext" style={{ width: 200 }}>
          {i18n.route.geoList.createNewDisableTip}
        </span>
      )}
    </button>
  );
};

export { CreateAction };
