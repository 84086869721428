import { useEffect } from 'react';

const isMac = typeof window !== 'undefined' && navigator.platform.toUpperCase().indexOf('MAC') >= 0;

const cmdCtrl = isMac ? 'metaKey' : 'ctrlKey';

interface IOptions {
  event?: 'keydown' | 'keyup';
  withModifier?: boolean;
  preventDefault?: boolean;
}

/**
 * Кастомный хук для использования шорткатов в компонентах
 * @param {string} keyCode - ключ кнопки (подсмотреть можно здесь – https://keycode.info, нужен event.key)
 * @param {function} handler - коллбэк
 * @param {object} [options] - параметры
 * @param {string} [options.event] - событие, по которому срабатывает шорткат
 * @param {boolean} [options.withModifier] - с кнопкой-модификатором или без
 * @param {boolean} [options.preventDefault] - блокировать событие по-умолчанию
 * @returns {undefined}
 *
 * @example
 * // Сабмит формы по нажатию CTRL/CMD+Enter
 * useShortcut('Enter', () => submitForm(), { withModifier: true });
 */
const useShortcut = (keyCode: string, handler: (e: KeyboardEvent) => void, options: IOptions = {}): void => {
  const { event = 'keyup', withModifier = false, preventDefault = true } = options;

  const eventHandler = (e: KeyboardEvent) => {
    const keyModifierPressed = e[cmdCtrl];
    const keyCodePressed = e.key === keyCode;

    if (withModifier && !keyModifierPressed) return;
    if (keyCode && !keyCodePressed) return;

    if (preventDefault) {
      e.preventDefault();
    }

    handler(e);
  };

  useEffect(() => {
    const cleanUp = () => {
      document.removeEventListener(event, eventHandler);
    };

    document.addEventListener(event, eventHandler);

    return cleanUp;
  });
};

export { useShortcut };
