import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { MouseEventHandler, ReactNode } from 'react';

import { ArrowButton } from '../ArrowButton';

import css from './Column.module.css';

interface ColumnProps {
  className?: string;
  header: ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
  isContentVisible: boolean;
  children?: ReactNode;
  width?: string | number;
  additional?: ReactNode;
  isHighlighted?: boolean;
  buttonClassName?: string;
  imitateBorder?: boolean;
}

const Column = (props: ColumnProps) => {
  const {
    header,
    onClick,
    children,
    isContentVisible,
    width,
    className,
    additional,
    isHighlighted,
    buttonClassName,
    imitateBorder,
  } = props;

  return (
    <div className={cn(css.container, className)} style={{ maxWidth: width }}>
      <div className={css.header}>
        {header && onClick ? (
          <>
            <ArrowButton
              className={cn(css.button, buttonClassName)}
              imitateColumnBorder={imitateBorder}
              isHighlighted={isHighlighted}
              isUp={isContentVisible}
              onClick={onClick}
            >
              <span className={css.buttonText}>{header}</span>
            </ArrowButton>
            {additional}
          </>
        ) : (
          <div>{header}</div>
        )}
      </div>
      {isContentVisible && <div className={css.content}>{children}</div>}
    </div>
  );
};

const connected = observer(Column);

export { connected as Column };
