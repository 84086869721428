import OptionsSelector from '@components/options-selector';
import { observer } from 'mobx-react-lite';

import { MobileScreen } from 'components/MobileScreen';
import withCargoTypes from 'hocs/withLoadingTypes';
import { useStore } from 'hooks/useStore';
import typeSelectorMobileCss from 'widgets/TypeSelector/TypeSelectorMobile.module.css';

const WrappedOptionsSelector = withCargoTypes(OptionsSelector);

const Mobile = () => {
  const {
    dictionaries: { data },
    app: { i18n },
    filter: {
      cargoType: { cargoTypes, setCargoTypes, clearCargoTypes },
    },
  } = useStore();

  const actions = {
    cancel: {
      onClick: clearCargoTypes,
      text: i18n.common.reset,
    },
  };

  const handleChange = (values: string[]) => {
    setCargoTypes(values);
  };

  return (
    <MobileScreen hasBackButton actions={actions} title={i18n.cargoTypes.label}>
      <WrappedOptionsSelector
        checkboxProps={{ size: 'medium' }}
        classNames={typeSelectorMobileCss}
        /* @ts-ignore */
        dictionary={data.cargoTypes}
        matchMedia="1px"
        selectedValues={cargoTypes}
        /* @ts-ignore */
        onChange={handleChange}
      />
    </MobileScreen>
  );
};

const connected = observer(Mobile);

export { connected as Mobile };
