import Tooltip from '@components/tooltip';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { TSubscribedFilter } from 'store/Subscriptions/SubscribedFilter';

import css from './Counters.module.css';

interface ICounters {
  filter: TSubscribedFilter;
}

const Counters = ({ filter }: ICounters) => {
  const {
    app: { i18n },
  } = useStore();

  const { hasNewLoads, hasUpdatedLoads } = filter.counters || {};
  const { hasNotifications } = filter?.instance || {};

  return (
    <span className={css.counters}>
      {hasNewLoads && (
        <Tooltip isWithoutWrapper content={i18n.subscriptions.counters.newLoads}>
          <span className={cn(css.counter, css.counter_new)} />
        </Tooltip>
      )}

      {hasUpdatedLoads && (
        <Tooltip isWithoutWrapper content={i18n.subscriptions.counters.updatedLoads}>
          <span className={cn(css.counter, css.counter_updated)} />
        </Tooltip>
      )}

      {hasNotifications && <span className={cn(css.counter, css.counter_error)} />}
    </span>
  );
};

const connected = observer(Counters);

export { connected as Counters };
