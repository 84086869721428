import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { formatDateToDayOfMonth, formatDateToDayOfWeek } from 'utils/dates';
import { capitalize } from 'utils/string/format';

import css from './List.module.css';

interface ListProps {
  dates: DateOption[];
}

const List = (props: ListProps) => {
  const {
    app: { locale, i18n },
    filter: { loadDate },
    ui: { toggleIsDateOptionsExpanded },
  } = useStore();
  const { dates } = props;

  const getDateOptionDesc = (date: DateOption) => {
    const dayOfMonthFormat = (date: Date | undefined) => date && formatDateToDayOfMonth(date, locale);
    const dayOfWeekFormat = (date: Date | undefined) => date && formatDateToDayOfWeek(date, locale);

    const daysOfMonth = [date.start, date.end].map(dayOfMonthFormat).filter(Boolean).join('—');
    const daysOfWeek = [date.start, date.end].map(dayOfWeekFormat).filter(Boolean).join('—');

    const description = [daysOfMonth, daysOfWeek].join(', ');

    return date.isUnclosed
      ? `${capitalize(i18n.dates.from)} ${description} ${i18n.dates.withoutRestrictions}`
      : description;
  };

  const handleChange = (id: DateOptionId) => {
    loadDate.setDateOption(id);
    toggleIsDateOptionsExpanded();
  };

  return (
    <ul className={cn(css.list, 'glz-z-dropdown-list glz-dropdown-list')}>
      {dates.map(date => {
        if (date.id === 'manual') return null;

        return (
          <li key={date.id} className={cn(css.listItem, 'glz-dropdown-list-item')}>
            <label className={cn('glz-radio glz-is-small', css.optionLabel)}>
              <input
                checked={date.id === loadDate.dateOption}
                className="glz-radio-input"
                type="radio"
                value={date.id || ''}
                onChange={() => handleChange(date.id)}
              />
              <div className="glz-radio-label">
                <div className={css.description}>
                  {date.caption}
                  <span>{getDateOptionDesc(date)}</span>
                </div>
              </div>
            </label>
          </li>
        );
      })}
    </ul>
  );
};

const connected = observer(List);

export { connected as List };
