import Tooltip from '@components/tooltip';
import cn from 'clsx';
import React, { ChangeEventHandler } from 'react';

import { withConditionalWrapping } from 'utils/hocs/withConditionalWrapping';

import css from './Checkbox.module.css';

interface CheckboxProps {
  small?: boolean;
  className?: string;
  disabled?: boolean;
  checked: boolean;
  children?: React.ReactNode;
  onChange: ChangeEventHandler;
  tooltip?: string | boolean;
  tooltipProps?: React.ComponentProps<typeof Tooltip>;
  contentClassName?: string;
}

const ConditionalTooltip = withConditionalWrapping(Tooltip);

const Checkbox = ({
  className,
  small = false,
  disabled,
  checked,
  children,
  onChange,
  tooltip,
  tooltipProps,
  contentClassName,
  ...restProps
}: CheckboxProps) => {
  return (
    <label
      {...restProps}
      className={cn(css.label, 'glz-checkbox', className, {
        'glz-is-small': small,
      })}
    >
      <ConditionalTooltip content={tooltip} wrapIf={Boolean(tooltip)} zIndex={{ tip: 100000 }} {...tooltipProps}>
        <input checked={checked} disabled={disabled} type="checkbox" onChange={onChange} />

        <span className={cn(css.content, contentClassName)}>{children}</span>
      </ConditionalTooltip>
    </label>
  );
};

export { Checkbox };
