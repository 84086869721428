import Icon from '@components/icon';
import IconWithActive from '@components/icon-with-active';
import Tooltip from '@components/tooltip';
import cn from 'clsx';
import { memo } from 'react';

import css from './QuestionTooltip.module.css';

type TipWithTitle = {
  title: string;
  desc: string;
};

interface QuestionTooltipProps {
  text: string | TipWithTitle;
  size?: number;
  tooltipWidth?: number | string;
  position?: string;
  url?: string;
  className?: string;
  colorInitial?: string;
  colorHover?: string;
}

const MemoIconWithActive = memo(
  ({ colorInitial, colorHover, size }: Pick<QuestionTooltipProps, 'colorInitial' | 'colorHover' | 'size'>) => (
    <IconWithActive
      activeIcon={<Icon color={colorHover} height={size} name="ic_question-solid" width={size} />}
      defaultIcon={<Icon color={colorInitial} height={size} name="ic_question-out" width={size} />}
    />
  ),
);
MemoIconWithActive.displayName = 'MemoIconWithActive';

const QuestionTooltip = (props: QuestionTooltipProps) => {
  const {
    size = 14,
    text,
    tooltipWidth = 230,
    className,
    colorInitial = 'var(--glz-color-neutral-tone-4)',
    colorHover = 'var(--glz-color-primary)',
  } = props;

  let tooltipContent;

  if (typeof text === 'object') {
    tooltipContent = (
      <>
        <strong className={css.title}>{text.title}</strong>
        <br />
        <span>{text.desc}</span>
      </>
    );
  }

  if (typeof text === 'string') {
    tooltipContent = text;
  }

  return (
    <Tooltip
      classNames={{
        wrapper: cn(css.container, className),
      }}
      content={tooltipContent}
      maxWidth={tooltipWidth}
    >
      <MemoIconWithActive colorHover={colorHover} colorInitial={colorInitial} size={size} />
    </Tooltip>
  );
};

export { QuestionTooltip };
