import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { interpolate } from 'utils/string/format';

import { SubscriptionMessage } from '../SubscriptionMessage';

const PaidSubscriptionMessage = () => {
  const {
    app: { i18n },
    savedFilters: { popup },
    profile,
    view,
  } = useStore();

  if (!popup) return null;

  const { channels, notificationsRate, smsParams, hasSubscriptionTrialAvailable } = popup;

  const formattedSubscriptionRate = view.atisCount(Number(notificationsRate));
  const formattedSmsRate = view.atisCount(smsParams?.rate);

  if (!profile.hasUnlimitedNotificationsLicense && !hasSubscriptionTrialAvailable) {
    return (
      <SubscriptionMessage>
        <strong>{i18n.subscriptions.paidSubscription}</strong>{' '}
        {[
          (channels.site.value || channels.email.value || channels.mobile.value) &&
            interpolate(i18n.subscriptions.atisPerLoad, { atis: formattedSubscriptionRate }),
          channels.sms.value && interpolate(i18n.subscriptions.smsNotificationPrice, { atis: formattedSmsRate }),
        ]
          .filter(Boolean)
          .join(' + ')}
      </SubscriptionMessage>
    );
  }

  return null;
};

const connected = observer(PaidSubscriptionMessage);

export { connected as PaidSubscriptionMessage };
