import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { ChangeEventHandler } from 'react';

import { RestrictedInput } from 'components/RestrictedInput';
import { inputRestriction } from 'constants/inputRestrictions';
import { useStore } from 'hooks/useStore';

import css from './MinMax.module.css';

interface MinMaxProps {
  min?: string;
  max?: string;
  className?: string;
  onMinChange?: ChangeEventHandler;
  onMaxChange?: ChangeEventHandler;
  onBlur?: (event: FocusEvent, value: string) => void;
  units?: string;
}

const MinMax = (props: MinMaxProps) => {
  const {
    app: { i18n },
  } = useStore();

  const { min, max, className, onMinChange, onMaxChange, onBlur, units } = props;

  return (
    <div className={cn(css.container, className)}>
      <RestrictedInput
        clearZeroOnBlur
        after={units}
        className={css.minInput}
        customRestrict={['excessLeadingZeros', 'leadingSymbols']}
        inputRestrictionType={inputRestriction.nonNegativeFloatDecimalLimit}
        maxDecimalPlaces={2}
        maxLength={4}
        placeholder={i18n.common.from}
        value={min}
        onInputBlur={onBlur}
        onValueChange={onMinChange}
      />
      <RestrictedInput
        clearZeroOnBlur
        after={units}
        className={css.maxInput}
        customRestrict={['excessLeadingZeros', 'leadingSymbols']}
        inputRestrictionType={inputRestriction.nonNegativeFloatDecimalLimit}
        maxDecimalPlaces={2}
        maxLength={4}
        placeholder={i18n.common.to}
        value={max}
        onInputBlur={onBlur}
        onValueChange={onMaxChange}
      />
    </div>
  );
};

const connected = observer(MinMax);

export { connected as MinMax };
