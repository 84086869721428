import { observer } from 'mobx-react-lite';

import { Field } from 'components/Field';
import { MinMax } from 'components/MinMax/MinMax';
import { useStore } from 'hooks/useStore';

import css from './Weight.module.css';

const Weight = () => {
  const {
    app: { i18n },
    filter: { weight },
  } = useStore();

  const labelText = `${i18n.weight.label}, ${i18n.common.tons}`;

  return (
    <Field containerClassName={css.weight} labelText={labelText}>
      <MinMax
        className={css.weightInputs}
        max={weight.max.view}
        min={weight.min.view}
        onBlur={weight.handleSwap}
        onMaxChange={weight.max.setValueFromInput}
        onMinChange={weight.min.setValueFromInput}
      />
    </Field>
  );
};

const connected = observer(Weight);

export { connected as Weight };
