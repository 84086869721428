import { zIndexSystem } from '@ati/uti';
import Field from '@components/field';
import Icon from '@components/icon';
import IconWithActive from '@components/icon-with-active';
import Link from '@components/link';
import Select from '@components/select';
import Tooltip from '@components/tooltip';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { Checkbox } from 'components/Checkbox';
import { PlusButton } from 'components/PlusButton';
import { subscriptionStats } from 'constants/pages';
import { useStore } from 'hooks/useStore';
import { AdditionalNotificationTypes } from 'store/OptionsStore';
import { PopupIds } from 'store/UIStore/Popups';
import { interpolate, toMoneyFormat } from 'utils/string/format';

import { MobileAppChannelCheckBox } from './MobileAppChannelCheckBox';

import css from './SubsSettings.module.css';

const SubsSettings: FC = () => {
  const {
    app: { i18n, env },
    savedFilters: { popup },
    ui: { popups },
  } = useStore();

  if (!popup) return null;

  const {
    channels: { site, email, sms, isOneChannelSelected },
  } = popup;

  const notificationTypesToDescription = {
    [AdditionalNotificationTypes.none]: i18n.filterPopup.notificationTypes.none.description,
    [AdditionalNotificationTypes.edited]: i18n.filterPopup.notificationTypes.edited.description,
    [AdditionalNotificationTypes.rateRased]: i18n.filterPopup.notificationTypes.rateRased.description,
  };

  const isSubscribed = popup?.isEdit && popup.validatedFilterInfo?.subscription;

  return (
    <>
      <div className={cn(css.header, { [css.header_wide]: popup.hasSubscription.value })}>
        <p className={cn('glz-p glz-is-size-1 glz-is-bold', css.title)}>
          {isSubscribed ? i18n.filterPopup.signedUp : i18n.filterPopup.signUp}
        </p>

        {popup.hasSubscription.value && (
          <Link
            before={<Icon height={16} name="ic_charts" width={16} />}
            href={interpolate(subscriptionStats, { env })}
            rel="noopener noreferrer"
            target="_blank"
            theme="gray"
          >
            {i18n.filterPopup.statistics}
          </Link>
        )}
      </div>

      <Checkbox
        checked={popup.hasSubscription.value}
        className={css.subscribeCheckbox}
        contentClassName={css.subscribeCheckboxContent}
        disabled={isSubscribed}
        onChange={popup.hasSubscription.setValueFromCheckbox}
      >
        <span className={css.subscribeText}>{i18n.filterPopup.subscribe}</span>
        {popup?.hasFreeSubscription && <span className={css.free}>{i18n.common.free}</span>}
      </Checkbox>

      {popup.hasSubscription.value && (
        <>
          <div className={css.channels}>
            <Checkbox
              small
              checked={site.value}
              disabled={site.value && isOneChannelSelected}
              tooltip={site.value && isOneChannelSelected && i18n.subscriptions.channels.disabledTooltip}
              tooltipProps={{ maxWidth: 270 }}
              onChange={site.setValueFromCheckbox}
            >
              {i18n.filterPopup.onSite}
            </Checkbox>

            {popup?.contact?.eMail ? (
              <Checkbox
                small
                checked={email.value}
                disabled={email.value && isOneChannelSelected}
                tooltip={email.value && isOneChannelSelected && i18n.subscriptions.channels.disabledTooltip}
                tooltipProps={{ maxWidth: 270 }}
                onChange={email.setValueFromCheckbox}
              >
                {i18n.filterPopup.email}
              </Checkbox>
            ) : (
              <PlusButton
                className={css.plusButton}
                text={i18n.filterPopup.email}
                onClick={() => popups.open(PopupIds.editContactPopup)}
              />
            )}

            <MobileAppChannelCheckBox />

            {popup?.smsParams?.phoneForSms ? (
              <div className={css.smsCheckboxContainer}>
                <Checkbox
                  small
                  checked={sms.value}
                  className={css.smsCheckbox}
                  disabled={sms.value && isOneChannelSelected}
                  tooltip={sms.value && isOneChannelSelected && i18n.subscriptions.channels.disabledTooltip}
                  tooltipProps={{ maxWidth: 270 }}
                  onChange={sms.setValueFromCheckbox}
                >
                  {i18n.filterPopup.sms} {popup?.smsParams?.phoneForSms}
                </Checkbox>

                {sms.value && (
                  <span className={css.smsCostContainer}>
                    <span className={css.smsCost}>+{toMoneyFormat(popup?.smsParams?.rate || 0)}</span>{' '}
                    {i18n.app.declensions.atis.few} {i18n.filterPopup.additionallyForEverySms}
                  </span>
                )}
              </div>
            ) : (
              <PlusButton
                className={css.plusButton}
                text={i18n.filterPopup.telephone}
                onClick={() => popups.open(PopupIds.editContactPopup)}
              />
            )}
          </div>

          <Field className={css.additionalNotifications} size="xlarge">
            <Select
              dropdownWidth={{
                min: '100%',
                current: '100%',
              }}
              labelProperty="label"
              options={popup.additionalNotifications.options}
              usePortal={false}
              value={popup.additionalNotifications.option}
              onChange={popup.additionalNotifications.setOption}
            />

            <Tooltip
              content={notificationTypesToDescription[popup.additionalNotifications.option.value]}
              maxWidth={330}
              zIndex={{ tip: zIndexSystem.abovePopup }}
            >
              <IconWithActive
                activeIcon={
                  <Icon color="var(--glz-color-primary)" height={14} name="ic_information-solid" width={14} />
                }
                className={css.icon}
                defaultIcon={
                  <Icon color="var(--glz-color-neutral-tone-3)" height={14} name="ic_information" width={14} />
                }
              />
            </Tooltip>
          </Field>
        </>
      )}
    </>
  );
};

const connected = observer(SubsSettings);

export { connected as SubsSettings };
