import Icon from '@components/icon';
import cn from 'clsx';
import React from 'react';

import css from './DefaultButton.module.css';

export type DefaultButtonProps = {
  active: boolean;
  gray?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  classNames?: {
    button?: string;
    icon?: string;
  };
};

const DefaultButton = ({ active, gray, children, disabled, classNames, ...restProps }: DefaultButtonProps) => (
  <span
    className={cn('glz-link glz-is-medium', classNames?.button, {
      'glz-is-gray': gray && !active,
      'glz-is-disabled': disabled,
    })}
    {...restProps}
  >
    {children}
    <Icon
      className={cn('glz-after-icon', classNames?.icon, { [css.icon_active]: active })}
      color="currentColor"
      height={5}
      name="ic_arrow-triangle-down"
      width={10}
    />
  </span>
);

export { DefaultButton };
