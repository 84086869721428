import cn from 'clsx';
import React from 'react';

import { GeoListType } from 'types/cargosApp/Geo';

import css from './GroupsList.module.css';

interface ListProps {
  data: GeoListType[];
  renderItem: Function;
  onSelect: (item: GeoListType) => void;
}

interface GroupListProps extends ListProps {
  groups: GeoListType[][];
}

const Groups = (props: GroupListProps) => {
  return (
    <>
      {props.groups.map((data: any, index: number) => {
        if (!data.length) return null;

        return (
          <React.Fragment key={index}>
            <List data={data} renderItem={props.renderItem} onSelect={props.onSelect} />
            {index !== props.groups.length - 1 && <hr className={cn(css.divider, 'glz-divider')} />}
          </React.Fragment>
        );
      })}
    </>
  );
};

const List = (props: ListProps) => {
  return (
    <>
      {props.data.map(item => (
        <li key={`list-item-${item.id}`} className={cn(css.item, 'glz-dropdown-list-item')}>
          <button
            className={css.itemButton}
            onClick={() => {
              props.onSelect(item);
            }}
          >
            {props.renderItem(item)}
          </button>
        </li>
      ))}
    </>
  );
};

const GroupsList = (props: GroupListProps) => {
  const { groups } = props;

  return <ul className={cn(css.list, 'glz-dropdown-list')}>{groups ? <Groups {...props} /> : <List {...props} />}</ul>;
};

export { GroupsList };
