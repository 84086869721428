import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { ArrowButton } from 'components/ArrowButton';
import { MobileScreen } from 'components/MobileScreen';
import { useStore } from 'hooks/useStore';
import { Time } from 'layout/Search/Filters/Time';
import { mobileScreenNames } from 'store/UIStore/MobileScreens';

import { Button } from './Button/Button';

import css from './Additional.module.css';

const Additional = () => {
  const {
    app: { i18n },
    ui: {
      mobileScreens: { showScreenFromElementAttribute, closeScreens },
    },
    filter: searchFilter,
  } = useStore();

  const additionalFilters = [
    {
      name: mobileScreenNames.dates,
      className: css.darkFilterBackground,
    },
    {
      name: mobileScreenNames.carTypes,
      className: css.darkFilterBackground,
    },
    {
      name: mobileScreenNames.cargoTypes,
      className: css.darkFilterBackground,
    },
    {
      name: mobileScreenNames.loadingTypes,
      className: css.darkFilterBackground,
    },
    {
      name: mobileScreenNames.payment,
      className: css.darkFilterBackground,
    },
    {
      name: mobileScreenNames.extraParams,
      className: css.darkFilterBackground,
    },
    {
      name: mobileScreenNames.dimensions,
      className: css.filterBackground,
    },
    {
      name: mobileScreenNames.boards,
      className: css.filterBackground,
      hasError: searchFilter.boards.isBoardsUnavailable.data,
    },
    {
      name: mobileScreenNames.firms,
      className: css.filterBackground,
    },
  ];

  return (
    <MobileScreen
      actions={{
        main: {
          onClick: closeScreens,
          text: i18n.common.save,
          buttonProps: { outlined: false },
        },
        cancel: {
          onClick: searchFilter.resetAdditionalFilters,
          text: i18n.common.clearAll,
        },
      }}
      mainActionAsGoBack={false}
      title={i18n.extraParams.label}
    >
      {additionalFilters.map(filter => (
        <ArrowButton
          key={filter.name}
          isRight
          buttonAttributes={{
            'data-screen': filter.name,
          }}
          className={cn(css.additionalFilter, filter.className)}
          hasError={filter.hasError}
          isHighlighted={searchFilter.isFilterFilled[filter.name]}
          onClick={showScreenFromElementAttribute}
        >
          {i18n[filter.name].label}
        </ArrowButton>
      ))}

      <div className={cn(css.timeOption, css.filterBackground)}>
        <Time />
      </div>
    </MobileScreen>
  );
};

Additional.Button = Button;

const connected = observer(Additional);

export { connected as Additional };
