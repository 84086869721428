import Icon from '@components/icon';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { mobileScreenNames } from 'store/UIStore/MobileScreens';

import css from './Button.module.css';

const Button = () => {
  const {
    ui: {
      mobileScreens: { showScreenFromElementAttribute },
    },
    filter: { filledFiltersCount },
    app: { i18n },
  } = useStore();

  return (
    <button
      className={cn(css.button, 'glz-link glz-is-medium glz-is-primary')}
      data-screen={mobileScreenNames.additionalFilters}
      onClick={showScreenFromElementAttribute}
    >
      <Icon className="glz-before-icon" color="currentColor" height={15} name="ic_filter-out-m" width={15} />
      {i18n.extraParams.label}
      {filledFiltersCount > 0 ? ` (${filledFiltersCount})` : ``}
    </button>
  );
};

const connected = observer(Button);

export { connected as Button };
