import BigNumber from 'bignumber.js';
import React from 'react';

import { DictionaryItem } from './types';

interface IWrapperedComponent {
  /** Массив с выбранными значениями */
  selectedValues: string[];
  /** Словарь для типов загрузки */
  dictionary: DictionaryItem[];
  /** Функция вызываемая при изменении. */
  onChange: (newValuesArray: string[]) => void;
}

function withLoadingTypes<T>(Component: React.ComponentType<T>) {
  const Wrapper = (props: T & IWrapperedComponent) => {
    const { onChange, selectedValues = [], dictionary = [], ...rest } = props;

    const handleLoadingTypeChange = (
      value: string,
      callback: (newValuesArray: string[], callbackValue: string, newValuesArrayLength: number) => void,
    ) => {
      let newValuesArray: string[] = [...selectedValues];
      if (newValuesArray.includes(value)) {
        newValuesArray = newValuesArray.filter(id => id !== value);
      } else {
        newValuesArray.push(value);
      }

      let bitsum = new BigNumber(0);
      newValuesArray.forEach(element => {
        bitsum = bitsum.plus(element);
      });

      if (onChange) {
        onChange(newValuesArray);
      }

      if (callback) {
        callback(newValuesArray, bitsum.toString(), newValuesArray.length);
      }
    };

    return (
      <Component
        {...(rest as T)}
        options={dictionary}
        selectedValues={selectedValues}
        onChange={handleLoadingTypeChange}
      />
    );
  };

  const displayName = Component.displayName || Component.name || 'Component';

  Wrapper.displayName = `withLoadingTypes(${displayName})`;

  return Wrapper;
}

export default withLoadingTypes;
