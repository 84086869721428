import css from './TitledLabel.module.css';

interface ITitleLabel {
  title: string;
  text: string;
}

const TitledLabel = (props: ITitleLabel) => (
  <span className={css.label}>
    <b>{props.title}</b>
    <span>{props.text}</span>
  </span>
);

export { TitledLabel };
