import DropdownButton from '@components/dropdown-button';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useShortcut } from 'hooks/useShortcut';
import { useStore } from 'hooks/useStore';

import css from './SearchButton.module.css';

const SearchButton = () => {
  const {
    app: { i18n, searchLoads, loading },
    filter,
    ui,
  } = useStore();

  const handleSearchClick = () => {
    if (loading.search) return;

    searchLoads();
  };

  const handleMenuClick = (menuOption: DropdownButtonOption) => {
    menuOption.handleClick();
  };

  useShortcut('Enter', () => {
    if (ui.shouldSearchByEnter) {
      handleSearchClick();
    }
  });

  const options = [
    {
      content: i18n.app.reverseSearch,
      handleClick: () => {
        filter.handleGeoSwap();
        searchLoads();
      },
      id: 'reverseSearch',
    },
  ];

  return (
    <div className={css.container}>
      <DropdownButton
        isSplited
        buttonProps={{
          loading: loading.search ? loading.search : undefined,
          disabled: loading.search,
        }}
        className={css.dropdownButton}
        classNames={{ button: css.button }}
        options={options}
        onClick={handleSearchClick}
        onMenuClick={handleMenuClick}
      >
        {i18n.app.searchLoads}
      </DropdownButton>
    </div>
  );
};

const connected = observer(SearchButton);

export { connected as SearchButton };
