import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { Checkbox } from 'components/Checkbox';
import { QuestionTooltip } from 'components/QuestionTooltip';
import { ellipseFaq } from 'constants/pages';
import { useStore } from 'hooks/useStore';
import { interpolate } from 'utils/string/format';

import { DistanceRange } from './DistanceRange';

import css from './RouteParams.module.css';

const RouteParams = () => {
  const {
    app: { i18n, env },
    filter: {
      routeParams: { isEllipseActive, isRouteLengthActive, setIsEllipseActive, setIsRouteLengthActive },
    },
  } = useStore();

  return (
    <div className={css.container}>
      <Checkbox
        small
        checked={isEllipseActive}
        className={cn(css.checkbox, { [css.checkbox_active]: isEllipseActive })}
        contentClassName={css.checkboxContent}
        onChange={() => setIsEllipseActive(!isEllipseActive)}
      >
        {i18n.routeParams.ellipseSearch}

        <a
          className={cn('glz-link', css.question)}
          href={interpolate(ellipseFaq, { env })}
          rel="noreferrer"
          target="_blank"
        >
          <QuestionTooltip text={i18n.routeParams.moreAboutEllipse} />
        </a>
      </Checkbox>

      <DistanceRange className={css.mobileDistanceRange} mode="ellipse" />

      <Checkbox
        small
        checked={isRouteLengthActive}
        className={cn(css.checkbox, { [css.checkbox_active]: isRouteLengthActive })}
        contentClassName={css.checkboxContent}
        onChange={() => setIsRouteLengthActive(!isRouteLengthActive)}
      >
        {i18n.routeParams.routeLength}
      </Checkbox>

      <DistanceRange className={css.mobileDistanceRange} mode="length" />
    </div>
  );
};

const connected = observer(RouteParams);

export { connected as RouteParams };
