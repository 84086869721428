import { observer } from 'mobx-react-lite';
import React from 'react';

import { Column } from 'components/Column';
import { useStore } from 'hooks/useStore';

import { Content } from './Content';
import { Mobile } from './Mobile';

interface DimensionsProps {
  className?: string;
}

const Dimensions = (props: DimensionsProps) => {
  const {
    app: { i18n },
    ui: { isAdditionalFiltersVisible, toggleIsAdditionalFiltersVisible },
    filter: { isDimensionsDefault },
  } = useStore();

  return (
    <Column
      className={props.className}
      header={i18n.dimensions.label}
      isContentVisible={isAdditionalFiltersVisible}
      isHighlighted={!isDimensionsDefault}
      onClick={toggleIsAdditionalFiltersVisible}
    >
      <Content />
    </Column>
  );
};

Dimensions.Mobile = Mobile;

const connected = observer(Dimensions);

export { connected as Dimensions };
