import { Cargo } from './Cargo';
import { Route } from './Route';

const CompactSummary = () => {
  return (
    <div>
      <Route />
      <Cargo />
    </div>
  );
};

export { CompactSummary };
