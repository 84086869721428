export const inputRestriction = {
  none: 'none',
  numbersOnly: 'numbersOnly',
  nonNegativeFloat: 'nonNegativeFloat',
  phoneNumber: 'phoneNumber',
  zeroTo9999Float: 'zeroTo9999Float',
  zeroTo99Float: 'zeroTo99Float',
  percents: 'percents',
  zeroTo99: 'zeroTo99',
  numbersWithSlash: 'numbersWithSlash',
  nonNegativeFloatDecimalLimit: 'nonNegativeFloatDecimalLimit',
};
