import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { Additional } from 'layout/Search/Filters/Additional/Mobile';
import { Boards } from 'layout/Search/Filters/Boards';
import { CarTypes } from 'layout/Search/Filters/CarTypes';
import { CargoTypes } from 'layout/Search/Filters/CargoTypes';
import { Dates } from 'layout/Search/Filters/Dates';
import { Dimensions } from 'layout/Search/Filters/Dimensions';
import { ExtraParams } from 'layout/Search/Filters/ExtraParams';
import { Firms } from 'layout/Search/Filters/Firms';
import { LoadingTypes } from 'layout/Search/Filters/LoadingTypes';
import { Payment } from 'layout/Search/Filters/Payment';
import { mobileScreenNames } from 'store/UIStore/MobileScreens';

const MobileScreens = () => {
  const {
    ui: { mobileScreens },
  } = useStore();

  return (
    <>
      {mobileScreens.current === mobileScreenNames.additionalFilters && <Additional />}
      {mobileScreens.current === mobileScreenNames.dates && <Dates.Mobile />}
      {mobileScreens.current === mobileScreenNames.carTypes && <CarTypes.Mobile />}
      {mobileScreens.current === mobileScreenNames.cargoTypes && <CargoTypes.Mobile />}
      {mobileScreens.current === mobileScreenNames.loadingTypes && <LoadingTypes.Mobile />}
      {mobileScreens.current === mobileScreenNames.payment && <Payment.Mobile />}
      {mobileScreens.current === mobileScreenNames.extraParams && <ExtraParams.Mobile />}
      {mobileScreens.current === mobileScreenNames.dimensions && <Dimensions.Mobile />}
      {mobileScreens.current === mobileScreenNames.boards && <Boards.Mobile />}
      {mobileScreens.current === mobileScreenNames.firms && <Firms.Mobile />}
    </>
  );
};

const connected = observer(MobileScreens);

export { connected as MobileScreens };
