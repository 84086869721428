import Icon from '@components/icon';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { nanoid } from 'nanoid';

import { DropdownToggle } from 'components/DropdownToggle';
import { useStore } from 'hooks/useStore';
import { TSubscribedFilter } from 'store/Subscriptions/SubscribedFilter';

import css from './Actions.module.css';

interface IFilterActions {
  filter: TSubscribedFilter;
  actions: TAction[];
}

type TAction = {
  id: string;
  onClick: () => void;
  icon: string;
  text: string;
  hidden?: boolean;
};

const Actions = ({ actions, filter }: IFilterActions) => {
  const {
    ui: { openedDropdownId, setOpenedDropdownId },
  } = useStore();

  const isOpened = filter.id === openedDropdownId;

  const handleToggle = () => {
    if (isOpened) {
      setOpenedDropdownId(null);
    } else {
      setOpenedDropdownId(filter.id);
    }
  };

  return (
    <DropdownToggle
      active={isOpened}
      className={css.dropdownToggle}
      classNames={{
        trigger: css.dropdownButton,
      }}
      dropdown={
        <ul className={cn(css.list, 'glz-dropdown-list')}>
          {actions
            .filter(action => !action.hidden)
            .map(action => (
              <li
                key={action.id}
                className={cn(css.action, 'glz-dropdown-list-item')}
                onClick={event => {
                  event.stopPropagation();
                  action.onClick();
                }}
              >
                <Icon className={css.icon} color="var(--glz-color-neutral-tone-4)" name={action.icon} size="s" />
                <span>{action.text}</span>
              </li>
            ))}
        </ul>
      }
      placement="bottom-left"
      onToggle={handleToggle}
    >
      <button className={css.actionsButton} id={nanoid()}>
        <Icon color="currentColor" name="ic_ellipsis" size="m" />
      </button>
    </DropdownToggle>
  );
};

const connected = observer(Actions);

export { connected as Actions };
