import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { Checkbox } from 'components/Checkbox';
import { QuestionTooltip } from 'components/QuestionTooltip';
import { useStore } from 'hooks/useStore';
import type { Board as BoardType } from 'store/FilterStore/BoardsStore/Board';
import { getContastColor } from 'utils/string/colors';

import css from './Board.module.css';

interface BoardProps {
  board: BoardType;
  className?: string;
}

const Board = (props: BoardProps) => {
  const {
    app: { i18n },
    ui: { isMobile },
  } = useStore();
  const { board } = props;

  return (
    <Checkbox
      checked={board.isSelected.value}
      className={css.container}
      disabled={board.isBoardDisabled}
      small={!isMobile}
      onChange={board.isSelected.setValueFromCheckbox}
    >
      <span
        className={cn(css.board, { [css.board_white]: board.isCommon }, props.className)}
        style={{ backgroundColor: board.color, color: getContastColor(board.color) }}
        title={board.name}
      >
        <span className={css.inner}>{board.name}</span>

        {board.isCommon && (
          <QuestionTooltip
            colorHover="var(--glz-color-neutral-tone-0)"
            colorInitial="var(--glz-color-neutral-tone-0)"
            text={i18n.boards.commonBoardTip}
          />
        )}
      </span>
    </Checkbox>
  );
};

const connected = observer(Board);

export { connected as Board };
