import UIPagination from '@components/pagination';
import cn from 'clsx';

import css from './Pagination.module.css';

const Pagination = (props: React.ComponentProps<typeof UIPagination>) => {
  const prevTipInactive = props.initialPage <= 1;
  const nextTipInactive = props.initialPage === props.pageCount;
  const prevTip = cn(props?.classNames?.prevTip, css.prevTipClassName, { [css.tipInactive]: prevTipInactive });
  const nextTip = cn(props?.classNames?.nextTip, css.nextTipClassName, { [css.tipInactive]: nextTipInactive });

  const handlePageChange = ({ selected }: { selected: number }) => {
    if (selected < 1) return;
    props.onPageChange({ selected });
  };

  return (
    <UIPagination
      {...props}
      classNames={{ ...props?.classNames, prevTip, nextTip }}
      tooltip={false}
      onPageChange={handlePageChange}
    />
  );
};

export { Pagination };
