import { observer } from 'mobx-react-lite';

import { Column } from 'components/Column';
import { useStore } from 'hooks/useStore';

import { Time } from '../Time';
import { Content } from './Content';
import { Mobile } from './Mobile';

import css from './Firms.module.css';

interface FirmsProps {
  className?: string;
}

const Firms = (props: FirmsProps) => {
  const {
    app: { i18n },
    ui: { isAdditionalFiltersVisible, toggleIsAdditionalFiltersVisible },
    filter: {
      firms: { isDefault },
    },
  } = useStore();

  const additionalFilters = (
    <div className={css.sortingTimeSelects}>
      <Time />
    </div>
  );

  return (
    <Column
      imitateBorder
      additional={additionalFilters}
      buttonClassName={css.columnButton}
      className={props.className}
      header={i18n.firms.label}
      isContentVisible={isAdditionalFiltersVisible}
      isHighlighted={!isDefault}
      onClick={toggleIsAdditionalFiltersVisible}
    >
      <Content />
    </Column>
  );
};

Firms.Mobile = Mobile;

const connected = observer(Firms);

export { connected as Firms };
