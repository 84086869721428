import isChildBitwiseForStrings from 'ati-utils/isChildBitwiseForStrings';
import BigNumber from 'bignumber.js';
import React, { useEffect, useRef } from 'react';

import {
  AdditionalFields,
  CarType,
  CarTypeStructure,
  DetailsTypes,
  DictionaryItem,
  IComponent,
  IWrapperedComponent,
  ResultObject,
} from './types';

const isoterm = '8';
const closedAndIsoterm = '91';
const refAndIsoterm = '844424930131980';

const sortValues = (values: string[], idsQueue: Array<string | number>) =>
  values.sort((a: string, b: string) => idsQueue.indexOf(a) - idsQueue.indexOf(b));

// подготовка вложенной структуры
// перебор всех типов в поисках детей и родителей
const getAdditionalFields = (dictionary: DictionaryItem[], parentId: string) => {
  const result: AdditionalFields = {
    children: {},
  };
  const bigIntParentIdString = new BigNumber(parentId).toString(2);

  dictionary.forEach((carType: DictionaryItem) => {
    // отфильтровываем изотерм (8 тип), он имеет отдельный обработчик
    if (carType.mask !== '8' && parentId !== '8') {
      const bigIntChildIdString = new BigNumber(carType.mask).toString(2);

      const isChildBitwise = isChildBitwiseForStrings(bigIntParentIdString, bigIntChildIdString);

      if (isChildBitwise && bigIntParentIdString !== bigIntChildIdString) {
        // задаем поля для ребенка, хранящегося в родителе
        result.children[carType.mask] = {
          id: carType.mask,
          key: carType.baseType,
          name: carType.carType,
          shortName: carType.short,
          position: parseInt(carType.typeOrder),
          isChild: true,
          children: {},
        };
      }
    }
    // задаем поля для родителя
    result.hasIsoterm = parentId === closedAndIsoterm || parentId === refAndIsoterm;
    result.isChild = false;
  });

  return result;
};

// подготовка структуры к работе с вложенными типами
// это основная вызываемая функция для подготовки
const getCarTypeStructure = (dictionary: DictionaryItem[]) => {
  const result: CarTypeStructure = {};
  dictionary.forEach((carType: DictionaryItem) => {
    const id = carType.mask;
    const additionalFields = getAdditionalFields(dictionary, id);

    result[id] = {
      id: carType.mask,
      key: carType.mask,
      name: carType.carType,
      shortName: carType.short,
      position: parseInt(carType.typeOrder),
      ...additionalFields,
    };
  });

  // удаляем детей из основного списка, так как они уже включены в родителей
  // нужно пробегать по готовому списку result
  Object.keys(result).forEach(carType => {
    if (result[carType]) {
      Object.keys(result[carType].children).forEach(childId => {
        if (result[childId]) {
          delete result[childId];
        }
      });
    }
  });

  return result;
};

function withCarTypes<T>(Component: React.ComponentType<T & IComponent>) {
  const Wrapper = (props: T & IWrapperedComponent) => {
    const { onChange, selectedValues = [], dictionary = [], ...rest } = props;

    const [listOfAllTypes, setListOfAllTypes] = React.useState<CarType[]>([]);

    const carTypesStructure = useRef<CarTypeStructure>({});
    const idsQueue = useRef<string[]>([]);
    const withIsoterm = useRef<string[]>([]);

    useEffect(() => {
      carTypesStructure.current = getCarTypeStructure(dictionary);
      const newListOfAllTypes: CarType[] = [];
      Object.values(carTypesStructure.current).forEach((item: CarType) => {
        newListOfAllTypes.push(item);
        if (item.children) {
          Object.values(item.children).forEach(child => {
            newListOfAllTypes.push(child);
          });
        }
      });
      setListOfAllTypes(newListOfAllTypes);

      newListOfAllTypes.sort((a, b) => a.position - b.position);
      idsQueue.current = newListOfAllTypes.map(item => item.id);
      withIsoterm.current = newListOfAllTypes.filter(option => option.hasIsoterm).map(option => option.id);
    }, [dictionary]);

    const getIdDetails = (id: string) => {
      const details: DetailsTypes = {
        id,
        checked: !selectedValues.includes(id),
        parent: null,
        children: [],
        isParent: false,
        isChild: false,
        isIsoterm: id === isoterm,
        hasIsoterm: false,
      };

      if (!carTypesStructure.current[id] && !details.isIsoterm) {
        Object.keys(carTypesStructure.current).forEach((carTypeId: string) => {
          const childrenIds = Object.keys(carTypesStructure.current[carTypeId].children);
          if (childrenIds.some(child => child === id)) {
            details.children = childrenIds;
            details.parent = carTypeId;
            details.isChild = true;
            details.hasIsoterm = carTypesStructure.current[carTypeId].hasIsoterm;
          }
        });

        return details;
      }

      if (details.isIsoterm) {
        details.isChild = true;
        details.hasIsoterm = true;
        return details;
      }

      details.children = Object.keys(carTypesStructure.current[id].children);
      details.isParent = true;
      details.hasIsoterm = !!carTypesStructure.current[id].hasIsoterm;

      return details;
    };

    const checkWithIsoterm = () => {
      const arr: string[] = [];

      withIsoterm.current.forEach(item => {
        const selectedChildrenGroup = Object.keys(carTypesStructure.current[item].children).every(child =>
          selectedValues.includes(child),
        );
        if (selectedChildrenGroup) {
          arr.push(item);
        }
      });
      return arr;
    };

    const setInputValues = (values: string[]) => {
      const exclude: string[] = [];
      const reverseExclude = [];
      values.forEach(id => {
        const parent = carTypesStructure.current[id];
        if (parent && parent.id !== isoterm) {
          const children = Object.keys(parent.children);
          if (parent.hasIsoterm) {
            children.push(isoterm);
          }
          if (children.length) {
            exclude.push(...children);
            reverseExclude.push(parent.id);
          }
        }
      });
      return {
        values: values.filter(item => !exclude.includes(item)),
        checkedNumber: values.length - reverseExclude.length,
      };
    };

    const onValuesChange = (
      values: string[],
      callback: (valuesCallback: string[], bitsum: string, resultNumber: number) => void,
    ) => {
      sortValues(values, idsQueue.current);
      const resultObject: ResultObject = setInputValues(values);
      const inputValues = resultObject.values;

      let bitsum = new BigNumber(0);
      inputValues.forEach((element: BigNumber.Value) => {
        bitsum = bitsum.plus(element);
      });

      const checkValue = inputValues.filter(item => withIsoterm.current.includes(item));

      if (checkValue.length > 1) {
        bitsum = bitsum.minus(Number(isoterm) * (checkValue.length - 1));
      }

      if (onChange) {
        onChange(values, bitsum.toString(), resultObject.checkedNumber);
      }

      if (callback) {
        callback(values, bitsum.toString(), resultObject.checkedNumber);
      }
    };

    const selectParentOption = (details: DetailsTypes) => {
      const { id, checked, hasIsoterm, children } = details;

      const ids = [id, ...children];

      if (checked) {
        if (hasIsoterm) {
          ids.push(isoterm, ...checkWithIsoterm());
        }

        const selVal = [...selectedValues];

        ids.forEach(item => {
          if (!selVal.includes(item)) {
            selVal.push(item);
          }
        });

        return selVal;
      }

      if (hasIsoterm && !withIsoterm.current.every(item => selectedValues.includes(item))) {
        ids.push(isoterm);
      }
      const values = selectedValues.filter(item => !ids.includes(item));

      return values;
    };

    const selectChildOption = (details: DetailsTypes) => {
      const { id, checked, hasIsoterm, parent, children, isIsoterm } = details;

      if (checked) {
        const parents: string[] = [];
        const selVal = [...selectedValues];
        selVal.push(id);

        if (hasIsoterm) {
          children.push(isoterm);
        }

        if (isIsoterm) {
          parents.push(...checkWithIsoterm());
        }

        if (parent && children.every(item => selVal.includes(item))) {
          parents.push(parent);
        }
        selVal.push(...parents);

        return selVal;
      }

      const ids: string[] = [id];
      if (parent) ids.push(parent);
      if (isIsoterm) ids.push(...withIsoterm.current);
      const newSelectedValues = selectedValues.filter(item => !ids.includes(item));

      return newSelectedValues;
    };

    const handleOptionChange = (
      id: string,
      callback: (valuesCallback: string[], bitsum: string, resultNumber: number) => void,
    ) => {
      const details: DetailsTypes = getIdDetails(id);
      let values: string[] = [];

      if (details.isParent) {
        values = selectParentOption(details);
      }

      if (details.isChild) {
        values = selectChildOption(details);
      }

      onValuesChange(values, callback);
    };

    return (
      <Component
        {...(rest as T)}
        options={listOfAllTypes}
        selectedValues={selectedValues}
        onChange={handleOptionChange}
      />
    );
  };

  const displayName = Component.displayName || Component.name || 'Component';

  Wrapper.displayName = `withCarTypes(${displayName})`;

  return Wrapper;
}

export default withCarTypes;
