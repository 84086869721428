import UIDropdownToggle from '@components/dropdown-toggle';
import cn from 'clsx';

import { DefaultButton } from './DefaultButton';

import css from './DropdownToggle.module.css';

type DropdownProps = {
  additionalAction?: React.ReactNode;
  classNames?: {
    additionalAction?: string;
  };
};

const DropdownToggle = (props: DropdownProps & React.ComponentProps<typeof UIDropdownToggle>) => {
  const { additionalAction, ...restProps } = props;

  return (
    <UIDropdownToggle
      {...restProps}
      dropdown={
        <>
          {props.dropdown}
          {Boolean(additionalAction) && (
            <div className={cn(css.additionalAction, props.classNames?.additionalAction)}>{additionalAction}</div>
          )}
        </>
      }
    >
      {props.children}
    </UIDropdownToggle>
  );
};

DropdownToggle.DefaultButton = DefaultButton;

export { DropdownToggle };
