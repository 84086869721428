import Icon from '@components/icon';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import type { FC } from 'react';

import { HStack } from 'components/HStack';

import css from './PlusButton.module.css';

const PlusButton: FC<TPlusButtonProps> = ({ text, className, onClick }) => {
  return (
    <HStack className={cn(css.stack, className)} gap={5}>
      <Icon color="var(--glz-color-primary)" name="ic_add-circle" size="s" />
      <button className="glz-link glz-is-medium" onClick={onClick}>
        {text}
      </button>
    </HStack>
  );
};

export type TPlusButtonProps = {
  text?: string;
  className?: string;
  onClick: () => void;
};

const connected = observer(PlusButton);

export { connected as PlusButton };
