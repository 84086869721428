import Icon from '@components/icon';
import { observer } from 'mobx-react-lite';

import { scrollTopAnchorId } from 'constants/ui';
import { useStore } from 'hooks/useStore';
import { scrollTo } from 'utils/nativeDOM';

import css from './ScrollTopButton.module.css';

const ScrollTopButton = () => {
  const { ui } = useStore();

  const handleClick = () => {
    scrollTo(`#${scrollTopAnchorId}`, { block: 'start' });
  };

  return (
    <div className={css.container}>
      {ui.isSticky && (
        <button className={css.button} onClick={handleClick}>
          <Icon color="var(--glz-color-neutral-tone-4)" height={26} name="ic_arrow-up" width={26} />
        </button>
      )}
    </div>
  );
};

const connected = observer(ScrollTopButton);

export { connected as ScrollTopButton };
