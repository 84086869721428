/* withLoadingTypes также подходит и для наименований*/
import OptionsSelector from '@components/options-selector';
import { observer } from 'mobx-react-lite';

import { Column } from 'components/Column';
import { OptionsList } from 'components/OptionsList';
import withCargoTypes from 'hocs/withLoadingTypes';
import { useStore } from 'hooks/useStore';
import { TypeSelector } from 'widgets/TypeSelector';

import { Mobile } from './Mobile/Mobile';

const WrappedOptionsSelector = withCargoTypes(OptionsSelector);
const WrappedOptionList = withCargoTypes(OptionsList);

interface CargoTypesProps {
  className?: string;
}

const CargoTypes = (props: CargoTypesProps) => {
  const {
    dictionaries: { data },
    app: { i18n },
    ui: { isAdvancedFiltersVisible, toggleIsAdvancedFiltersVisible },
    filter: {
      cargoType: { cargoTypes, setCargoTypes, clearCargoTypes, isDefault },
    },
  } = useStore();

  const cargoTypesShortListIds = ['42', '87', '80', '46', '34'];

  const handleChange = (values: string[]) => {
    setCargoTypes(values);
  };

  return (
    <Column
      className={props.className}
      header={i18n.cargoTypes.label}
      isContentVisible={isAdvancedFiltersVisible}
      isHighlighted={!isDefault}
      onClick={toggleIsAdvancedFiltersVisible}
    >
      <TypeSelector
        OptionsList={WrappedOptionList}
        OptionsSelector={WrappedOptionsSelector}
        dictionary={data.cargoTypes}
        initialButtonText={i18n.cargoTypes.allTypes}
        selectedCount={cargoTypes.length}
        selectedValues={cargoTypes}
        shortListIds={cargoTypesShortListIds}
        onChange={handleChange}
        onClear={clearCargoTypes}
      />
    </Column>
  );
};

CargoTypes.Mobile = Mobile;

const connected = observer(CargoTypes);

export { connected as CargoTypes };
