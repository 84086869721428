const PromoIcon = () => {
  return (
    <svg fill="none" height="94" viewBox="0 0 350 425" width="77" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)">
        <mask height="425" id="b" maskUnits="userSpaceOnUse" width="350" x="0" y="0">
          <path d="M350 0H0v425h350V0Z" fill="#fff" />
        </mask>
        <g mask="url(#b)">
          <path
            clipRule="evenodd"
            d="M50.88 25.44A38.16 38.16 0 0 0 12.72 63.6v68.7a11.67 11.67 0 0 0-6.36 10.39v33.63c0 4.53 2.58 8.46 6.36 10.39v7.69a11.67 11.67 0 0 0-6.36 10.39v33.63c0 4.53 2.58 8.46 6.36 10.4v266.35a38.16 38.16 0 0 0 38.16 38.16h228.96A38.16 38.16 0 0 0 318 515.17v-298.9c3.8-2.2 6.36-6.32 6.36-11.03V174.8c0-4.7-2.55-8.82-6.36-11.02V63.6a38.16 38.16 0 0 0-38.16-38.16H50.88Z"
            fill="#1F3441"
            fillRule="evenodd"
          />
          <path
            d="M273.48 69.96H57.24A25.44 25.44 0 0 0 31.8 95.4v413.4a25.44 25.44 0 0 0 25.44 25.45h216.24a25.44 25.44 0 0 0 25.44-25.44V95.4a25.44 25.44 0 0 0-25.44-25.45Z"
            fill="#FFFDFA"
          />
          <path d="M197.16 44.52H127.2a6.36 6.36 0 1 0 0 12.72h69.96a6.36 6.36 0 0 0 0-12.72Z" fill="#fff" />
          <path
            clipRule="evenodd"
            d="m165.02 160.4 31.47 18.21-84.04 48.54-31.5-18.21 84.07-48.54Zm7.58 108.22 9.08-5.26 72.72-41.97v94.5l-81.8 47.24v-94.5Zm76.46-59.68-36.25-20.65-84.04 48.53 4.8 2.48 31.46 18.17 31.29-18.04 52.74-30.5Zm-141.7 54.89 15.06 7.74v-23.01l35.12 20.23v93.84l-81.22-46.9v-93.84l31.04 17.88v24.06Z"
            fill="#64B82A"
            fillRule="evenodd"
          />
          <path
            d="M279.84 133.56a63.6 63.6 0 1 0 0-127.2 63.6 63.6 0 0 0 0 127.2Z"
            fill="#FFC108"
            stroke="#fff"
            strokeWidth="12.72"
          />
          <path
            clipRule="evenodd"
            d="m304.2 79.27 7.44 7.67v3.92h-63.6v-3.92l7.46-7.67V58.15c0-5.8 1.76-10.99 5.3-15.59 3.53-4.6 8-7.52 13.41-8.77v-2.56c0-1.59.56-2.95 1.66-4.09a5.35 5.35 0 0 1 3.97-1.7c1.55 0 2.88.57 3.98 1.7a5.66 5.66 0 0 1 1.66 4.1v2.55a23.07 23.07 0 0 1 13.41 8.77 24.9 24.9 0 0 1 5.3 15.59v21.12Zm-31.31 15.17h13.91c0 1.93-.69 3.63-2.07 5.1a6.48 6.48 0 0 1-9.77 0 7.26 7.26 0 0 1-2.07-5.1Z"
            fill="#39444F"
            fillRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path d="M0 0h350v425H0z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { PromoIcon };
