import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { Checkbox } from 'components/Checkbox';
import { useStore } from 'hooks/useStore';
// import { GeoInformer } from 'layout/Search/Filters/Route/GeoInformer';
import { capitalize } from 'utils/string/format';
import { Geo } from 'widgets/Geo';

import css from './Route.module.css';

const Route = () => {
  const {
    app: { i18n },
    filter: { from, to, handleGeoSwap, truckId, isTruckInfoIncluded, toggleIsTruckInfoIncluded },
  } = useStore();

  return (
    <div className={css.route}>
      {/* TODO:
       * из-за рендерного условия с typeof window внутри GeoInformer,
       * происходит расхождение между отрендереным на сервере и тем, что рендерится после гидрации
       * а если динамически импортировать, то вместо инпута будет пустота временно
       * геоинформер у нас с самого начала не работал, поэтому пока мы не придумаем, как исправить проблемы выше,
       * он будет закомменчен, и вместо него будет div
       */}
      {/* <GeoInformer className={cn(css.geoWrapper, css.geoWrapperFrom)}> */}
      <div className={cn(css.geoWrapper, css.geoWrapperFrom)}>
        <Geo
          dataQa="autosuggestFrom"
          exactSearch={i18n.route.exactSearch.from}
          label={i18n.route.from.label}
          placeholder={capitalize(i18n.route.from.placeholder)}
          point={from}
        />
      </div>
      {/* </GeoInformer> */}

      <div className={css.swapWrapper}>
        <button className={css.swap} onClick={handleGeoSwap}>
          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.99 11L3 15l3.99 4v-3H14v-2H6.99v-3zM21 9l-3.99-4v3H10v2h7.01v3L21 9z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </button>
      </div>

      <div className={cn(css.geoWrapper, css.geoWrapperTo)}>
        <Geo
          dataQa="autosuggestTo"
          exactSearch={i18n.route.exactSearch.to}
          label={i18n.route.to.label}
          placeholder={capitalize(i18n.route.to.placeholder)}
          point={to}
        />

        {Boolean(truckId) && (
          <Checkbox small checked={isTruckInfoIncluded} className={css.truckInfo} onChange={toggleIsTruckInfoIncluded}>
            {i18n.route.includeTruckInfo}
          </Checkbox>
        )}
      </div>
    </div>
  );
};

const connected = observer(Route);

export { connected as Route };
