import { observer } from 'mobx-react-lite';

import { MobileScreen } from 'components/MobileScreen';
import { OptionsList } from 'components/OptionsList';
import { useStore } from 'hooks/useStore';

import { ADR } from '../ADR';

const Mobile = () => {
  const {
    app: { i18n },
    filter: { extraParams },
    options,
  } = useStore();

  const actions = {
    cancel: {
      onClick: extraParams.resetExtraParams,
      text: i18n.common.reset,
    },
  };

  return (
    <MobileScreen hasBackButton actions={actions} title={i18n.extraParams.label}>
      <OptionsList
        options={options.getExtraParams()}
        selectedValues={extraParams.params}
        valueProperty="id"
        onChange={extraParams.setParam}
      />

      <ADR />
    </MobileScreen>
  );
};

const connected = observer(Mobile);

export { connected as Mobile };
