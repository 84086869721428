import Popover from '@components/popover';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { Column } from 'components/Column';
import { OptionsList } from 'components/OptionsList';
import { enablerFeatureTitles } from 'constants/features';
import { adrFaq } from 'constants/pages';
import { useStore } from 'hooks/useStore';
import { SiteFeaturesIDs } from 'store/SiteFeatures/SiteFeatures';
import { interpolate } from 'utils/string/format';

import { ADR } from './ADR';
import { Mobile } from './Mobile';

import css from './ExtraParams.module.css';

interface ExtraParamsProps {
  className?: string;
}

const ExtraParams = (props: ExtraParamsProps) => {
  const {
    app: { i18n, env },
    profile,
    enablerFeaturesRepo,
    siteFeatures,
    ui: { isAdvancedFiltersVisible, toggleIsAdvancedFiltersVisible },
    filter: { extraParams, isExtraParamsDefault },
    options,
  } = useStore();
  const [isPopoverVisible, setIsPopoverVisible] = useState(true);

  const shouldShowAnnouncementPopover = Boolean(
    siteFeatures.isSiteFeatureEnabled[SiteFeaturesIDs.extendedADR] &&
      profile.data?.isUser &&
      isPopoverVisible &&
      enablerFeaturesRepo.featuresByTitle[enablerFeatureTitles.announcementADR]?.isEnabled &&
      !enablerFeaturesRepo.featuresByTitle[enablerFeatureTitles.announcementADR]?.isViewed,
  );

  const content = (
    <>
      <Popover.Header withoutContent className={css.popoverHeader} type="small">
        {i18n.ADR.announcement.description}
      </Popover.Header>
      <Popover.Footer>
        <a
          className="glz-link glz-is-medium glz-is-primary"
          href={interpolate(adrFaq, { env })}
          rel="noreferrer"
          target="_blank"
        >
          {i18n.ADR.announcement.link}
        </a>
      </Popover.Footer>
    </>
  );

  return (
    <Popover
      classNames={{
        childrenWrapper: props.className,
      }}
      content={content}
      size="small"
      theme="yellow"
      visible={shouldShowAnnouncementPopover}
      onCloseClick={() => {
        setIsPopoverVisible(false);
        enablerFeaturesRepo.markAsViewedByTitle(enablerFeatureTitles.announcementADR);
      }}
    >
      <Column
        header={i18n.extraParams.label}
        isContentVisible={isAdvancedFiltersVisible}
        isHighlighted={!isExtraParamsDefault}
        onClick={toggleIsAdvancedFiltersVisible}
      >
        <OptionsList
          options={options.getExtraParams()}
          selectedValues={extraParams.params}
          valueProperty="id"
          onChange={extraParams.setParam}
        />

        <ADR />
      </Column>
    </Popover>
  );
};

ExtraParams.Mobile = Mobile;

const connected = observer(ExtraParams);

export { connected as ExtraParams };
