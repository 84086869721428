import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';

import css from './OptionsList.module.css';

export type Option = {
  id: string;
  name: string;
  short?: string;
};

export interface OptionsListProps {
  options: Option[];
  visibleIds?: string[];
  valueProperty: keyof Pick<Option, 'id' | 'name'>;
  selectedValues: string[];
  onChange: Function;
  labelClassName?: string;
  useShortName?: boolean;
}

const maxNameLength = 18;

const OptionsList = (props: OptionsListProps) => {
  const {
    ui: { isMobile },
  } = useStore();

  const { options, valueProperty, visibleIds, labelClassName, onChange, selectedValues, useShortName } = props;

  return (
    <div className={css.container}>
      {options.map((option: Option) => {
        if (visibleIds?.length && !visibleIds.includes(option.id)) return null;

        return (
          <label
            key={option[valueProperty]}
            className={cn(css.listItem, labelClassName, 'glz-checkbox', isMobile ? 'glz-is-medium' : 'glz-is-small')}
          >
            <input
              checked={selectedValues.includes(option[valueProperty])}
              type="checkbox"
              value={option[valueProperty]}
              onChange={event => onChange(event.target.value)}
            />
            <span className={css.optionName}>
              {useShortName && option.name.length > maxNameLength ? option.short : option.name}
            </span>
          </label>
        );
      })}
    </div>
  );
};

const connected = observer(OptionsList);

export { connected as OptionsList };
