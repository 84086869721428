import cn from 'clsx';
import { ReactNode } from 'react';

import css from './NoContent.module.css';

interface INoContent {
  icon: ReactNode;
  title: string;
  desc: string | ReactNode;
  actionElement?: ReactNode;
  className?: string;
  titleSize?: number;
}

const NoContent = ({ icon, title, desc, actionElement, className, titleSize = 5 }: INoContent) => {
  return (
    <div className={cn(css.container, className)}>
      <span className={css.icon}>{icon}</span>
      <span className={cn(css.title, 'glz-h', `glz-is-size-${titleSize}`)}>{title}</span>
      <span className={css.desc}>{desc}</span>
      {actionElement}
    </div>
  );
};

export { NoContent };
