import Skeleton from '@components/skeleton';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';

import css from './PrettyLoadPreloader.module.css';

const PrettyLoadPreloader = ({ count = 5 }) => {
  const {
    ui: { isMobile },
  } = useStore();

  if (isMobile) {
    return (
      <div>
        {Array(count)
          .fill(null)
          .map((_, key) => (
            <div key={key} className={css.preloader}>
              <Skeleton height={60} />
              <Skeleton height={30} />
              <Skeleton height={20} />
              <Skeleton height={20} />
            </div>
          ))}
      </div>
    );
  }

  return (
    <div>
      {Array(count)
        .fill(null)
        .map((_, key) => (
          <div key={key} className={css.preloader}>
            <Skeleton height={70} />
            <Skeleton height={70} />
            <Skeleton height={70} />
            <Skeleton height={70} />
            <Skeleton height={70} />
            <Skeleton height={70} />
            <Skeleton height={70} />
            <Skeleton height={70} />
            <Skeleton height={70} />
          </div>
        ))}
    </div>
  );
};

const connected = observer(PrettyLoadPreloader);

export { connected as PrettyLoadPreloader };
