import Icon from '@components/icon';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';

import css from './SubscriptionsStatus.module.css';

const SubscriptionsStatus = () => {
  const {
    subscriptions: { hasError, hasNewLoads, hasUpdatedLoads },
  } = useStore();

  if (!hasError && !hasNewLoads && !hasUpdatedLoads) return null;

  return (
    <Icon
      className={cn(css.status, {
        [css.status_updated]: hasUpdatedLoads,
        [css.status_new]: hasNewLoads,
        [css.status_error]: hasError,
      })}
      name="ic_dot"
      size="s"
    />
  );
};

const connected = observer(SubscriptionsStatus);

export { connected as SubscriptionsStatus };
