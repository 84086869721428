import Button from '@components/button';
import Popup from '@components/popup';
import { observer } from 'mobx-react-lite';

import { addInvoice } from 'constants/pages';
import { useStore } from 'hooks/useStore';
import { interpolate } from 'utils/string/format';

import css from './Footer.module.css';

const Footer = () => {
  const {
    app: { i18n, env },
    savedFilters: { popup, closePopup },
  } = useStore();

  if (!popup) return null;

  const handleSave = () => {
    if (popup.isLowBalance) {
      window.open(interpolate(addInvoice, { env }), '_blank')?.focus();
    }
    popup.saveFilter();
  };

  const handleUnsubscribe = () => {
    popup.unsubscribe();
    closePopup();
  };

  return (
    <>
      <Popup.ActionWrapper type="main">
        <Button fullWidth disabled={popup.isSaveDisabled} loading={popup.isSaving} theme="primary" onClick={handleSave}>
          {i18n.filterPopup.primaryAction[popup.mode]}
        </Button>
      </Popup.ActionWrapper>

      <Popup.ActionWrapper type="cancel">
        <a className="glz-link glz-is-medium glz-is-primary" onClick={closePopup}>
          {i18n.filterPopup.cancel}
        </a>
      </Popup.ActionWrapper>

      {popup.validatedFilterInfo?.subscription && (
        <Popup.ActionWrapper type="second">
          <Button fullWidth outlined className={css.unsubButton} theme="error" onClick={handleUnsubscribe}>
            {i18n.filterPopup.unsubscribe}
          </Button>
        </Popup.ActionWrapper>
      )}
    </>
  );
};

const connected = observer(Footer);

export { connected as Footer };
