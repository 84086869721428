import { observer } from 'mobx-react-lite';
import type { FC } from 'react';

import { FollowedLoadsPagination } from 'components/FollowedLoadsPagination';
import { SearchResultsPagination } from 'components/SearchResultsPagination';
import { useStore } from 'hooks/useStore';

import css from './StickyPagination.module.css';

const StickyPagination: FC = () => {
  const { ui } = useStore();

  switch (ui.activeTab) {
    case 'search':
      return <SearchResultsPagination className={css.pagination} tooltip={false} />;
    case 'favorites':
      return <FollowedLoadsPagination className={css.pagination} />;
    default:
      return null;
  }
};

const connected = observer(StickyPagination);

export { connected as StickyPagination };
