const TrucksIcon = () => (
  <svg fill="none" height="41" viewBox="0 0 41 41" width="41" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40.0408 11.4448L20.945 0.836999C20.6697 0.681002 20.3301 0.681002 20.0549 0.836999L0.968148 11.4448C0.674507 11.61 0.500157 11.9128 0.500157 12.2431V39.1022C0.490981 39.5977 0.885561 40.0015 1.38108 40.0015C1.39026 40.0015 1.39943 40.0015 1.39943 40.0015H39.6004C40.0959 40.0107 40.4997 39.6161 40.4997 39.1206C40.4997 39.1114 40.4997 39.1022 40.4997 39.1022V12.2523C40.5088 11.9128 40.3345 11.61 40.0408 11.4448ZM33.3513 38.1754H7.65767V29.0909H33.3513V38.1754ZM33.3513 27.2556H7.65767V23.7686H33.3513V27.2556ZM33.3513 21.9333H7.65767V18.4463H33.3513V21.9333ZM38.6736 38.1754H35.1866V17.5562C35.1866 17.0515 34.8103 16.6111 34.2965 16.6111H6.71251C6.20782 16.6111 5.82241 17.0515 5.82241 17.5562V38.1754H2.33542V12.7846L20.5045 2.68144L38.6736 12.7846V38.1754Z"
      fill="var(--glz-color-neutral-tone-3)"
    />
  </svg>
);

export { TrucksIcon };
