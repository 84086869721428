import OptionsSelector from '@components/options-selector';
import { observer } from 'mobx-react-lite';

import { Column } from 'components/Column';
import { OptionsList } from 'components/OptionsList';
import withLoadingTypes from 'hocs/withLoadingTypes';
import { useStore } from 'hooks/useStore';
import { TypeSelector } from 'widgets/TypeSelector';

import { Mobile } from './Mobile';

const WrappedOptionsSelector = withLoadingTypes(OptionsSelector);
const WrappedOptionList = withLoadingTypes(OptionsList);

const loadingTypesShortListIds = ['1', '4', '8', '65536'];

interface LoadingTypesProps {
  className?: string;
}

const LoadingTypes = (props: LoadingTypesProps) => {
  const {
    dictionaries: { data },
    app: { i18n },
    ui: { isAdvancedFiltersVisible, toggleIsAdvancedFiltersVisible },
    filter: {
      loadingType: { loadingTypes, clearLoadingTypes, setLoadingTypes, isDefault },
    },
  } = useStore();

  const handleChange = (values: string[]) => {
    setLoadingTypes(values);
  };

  return (
    <Column
      className={props.className}
      header={i18n.loadingTypes.label}
      isContentVisible={isAdvancedFiltersVisible}
      isHighlighted={!isDefault}
      onClick={toggleIsAdvancedFiltersVisible}
    >
      <TypeSelector
        useShortName
        OptionsList={WrappedOptionList}
        OptionsSelector={WrappedOptionsSelector}
        dictionary={data.loadingTypes}
        selectedCount={loadingTypes.length}
        selectedValues={loadingTypes}
        shortListIds={loadingTypesShortListIds}
        onChange={handleChange}
        onClear={clearLoadingTypes}
      />
    </Column>
  );
};

LoadingTypes.Mobile = Mobile;

const connected = observer(LoadingTypes);

export { connected as LoadingTypes };
