import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { interpolate } from 'utils/string/format';

import css from './PopularDestinations.module.css';

const PopularDestinations = () => {
  const { app } = useStore();

  return (
    <div className={css.container}>
      <div className={css.destinationsContainer}>
        <h3 className={cn(css.heading, 'glz-h', 'glz-is-size-3')}>{app.i18n.popularDestinations.heading}</h3>

        <div className={css.destinations}>
          {app.popularDestinations.destinations.map(destination => (
            <div key={destination.name}>
              <a
                className={cn('glz-link', 'glz-is-large', 'glz-is-primary', 'glz-is-underline')}
                href={interpolate(destination.url, { env: app.env })}
                rel="noreferrer"
                target="_blank"
              >
                {destination.name}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const connected = observer(PopularDestinations);

export { connected as PopularDestinations };
