import format from 'date-fns/format';
import en from 'date-fns/locale/en-US';
import hy from 'date-fns/locale/hy';
import kk from 'date-fns/locale/kk';
import ru from 'date-fns/locale/ru';
import uz from 'date-fns/locale/uz';

type Locale = keyof typeof locales;

const locales = {
  ru,
  en,
  kk,
  uz,
  hy,
  ky: ru,
};

export const formatDateToDayOfMonth = (date: Date, locale: Locale) => {
  return format(date, 'd MMMM', { locale: locales[locale] });
};

export const formatDateToDayOfWeek = (date: Date, locale: Locale) => {
  return format(date, 'EEEEEE', { locale: locales[locale] });
};
