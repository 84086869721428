import Icon from '@components/icon';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { Board } from 'components/Board';
import { Checkbox } from 'components/Checkbox';
import { MobileScreen } from 'components/MobileScreen';
import { QuestionTooltip } from 'components/QuestionTooltip';
import { locales } from 'constants/common';
import { auctionFaq, boardsFaq } from 'constants/pages';
import { useStore } from 'hooks/useStore';
import { Board as BoardType } from 'store/FilterStore/BoardsStore/Board';
import { interpolate } from 'utils/string/format';

import css from '../Boards.module.css';

const Mobile = () => {
  const {
    app: { i18n, env, locale },
    filter: {
      boards: { boards, withAuction, isBoardsUnavailable, toggleIsAllSelected, isAllSelected },
      resetBoards,
    },
    profile,
  } = useStore();

  const showBoardsFaqLink = locale === locales.ru && boards.length <= 1 && !isBoardsUnavailable.value;
  const showAuction = profile.data?.isUser;

  const actions = {
    cancel: {
      onClick: resetBoards,
      text: i18n.common.reset,
    },
  };

  return (
    <MobileScreen hasBackButton actions={actions} title={i18n.boards.label}>
      <ul>
        {!isBoardsUnavailable.value && (
          <Checkbox checked={isAllSelected} className={css.selectAllButton} onChange={toggleIsAllSelected}>
            {isAllSelected ? i18n.boards.unselectAll : i18n.boards.selectAll}
          </Checkbox>
        )}
        {boards.map((board: BoardType) => {
          return (
            <li key={board.id} className={css.visibleBoard}>
              <Board board={board} />
            </li>
          );
        })}
      </ul>

      {isBoardsUnavailable.value && (
        <p className={cn(css.errorMessage, 'glz-p glz-is-size-3 glz-has-text-red')}>
          {i18n.boards.boardsUnavailableDetails}
        </p>
      )}

      {showAuction && (
        <Checkbox checked={withAuction.value} className={css.showAuction} onChange={withAuction.setValueFromCheckbox}>
          {i18n.boards.auctionOnly}
          <a className="glz-link" href={interpolate(auctionFaq, { env })} rel="noreferrer" target="_blank">
            <QuestionTooltip text={i18n.boards.auctionTip} tooltipWidth={195} />
          </a>
        </Checkbox>
      )}

      {showBoardsFaqLink && (
        <a
          className={cn(css.link, 'glz-link', 'glz-is-underline', 'glz-is-gray')}
          href={interpolate(boardsFaq, { env })}
          rel="noreferrer"
          target="_blank"
        >
          {i18n.boards.boardsFaqLink}
          <Icon
            className={cn(css.icon, 'glz-after-icon')}
            color="currentColor"
            height={9}
            name="ic_external"
            width={9}
          />
        </a>
      )}
    </MobileScreen>
  );
};

const connected = observer(Mobile);

export { connected as Mobile };
