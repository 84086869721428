import cn from 'clsx';
import { MouseEventHandler, ReactNode } from 'react';

import css from './ArrowButton.module.css';

export function ArrowButton({
  children,
  isUp,
  isRight,
  onClick,
  isHighlighted,
  className,
  imitateColumnBorder,
  buttonAttributes,
  hasError,
}: IArrowButtonProps) {
  return (
    <button
      className={cn(className, css.headerButton, css.headerButton_isClickable, {
        [css.headerButton_highlighted]: isHighlighted,
        [css.imitateColumnBorder]: imitateColumnBorder,
        [css.headerButton_error]: hasError,
      })}
      type="button"
      onClick={onClick}
      {...buttonAttributes}
    >
      {children}
      <i className={cn(css.arrow, { [css.arrow_up]: isUp, [css.arrow_right]: isRight })} />
    </button>
  );
}

interface IArrowButtonProps {
  children?: ReactNode;
  isUp?: boolean;
  isRight?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isHighlighted?: boolean;
  hasError?: boolean;
  className?: string;
  imitateColumnBorder?: boolean;
  buttonAttributes?: {
    [key: string]: string;
  };
}
