type TBellIconProps = {
  type: 'error' | 'success';
  size?: 'medium' | 'large';
  theme?: 'filled' | 'outlined';
};

const sizes = {
  medium: [24, 24],
  large: [48, 48],
};

const BellIcon = ({ type, size = 'medium', theme = 'filled' }: TBellIconProps) => {
  const iconHeight = sizes[size][0];
  const iconWidth = sizes[size][1];

  return (
    <svg fill="none" height={iconHeight} viewBox="0 0 24 24" width={iconWidth} xmlns="http://www.w3.org/2000/svg">
      <path d="M6.5 20.5a3 3 0 0 0 6 0" fill={`var(--glz-color-${type})`} />
      {theme === 'filled' && (
        <path
          clipRule="evenodd"
          d="M1.543 11.358C1.543 7.294 4.88 4 9 4s7.457 3.294 7.457 7.358v5.075l1.44 1.218a.503.503 0 0 1 .103.304v2.538a.51.51 0 0 1-.514.507H.514A.51.51 0 0 1 0 20.492v-2.537c0-.11.036-.216.103-.304l1.44-1.218v-5.075Z"
          fill={`var(--glz-color-${type})`}
          fillRule="evenodd"
          opacity=".64"
        />
      )}
      <path
        clipRule="evenodd"
        d="M8 4a1 1 0 1 1 2 0v.066c3.646.482 6.457 3.563 6.457 7.292v5.075l1.44 1.218a.503.503 0 0 1 .103.304v2.538a.51.51 0 0 1-.514.507h-4.521a3.501 3.501 0 0 1-6.93 0H.515A.51.51 0 0 1 0 20.492v-2.537c0-.11.036-.216.103-.304L1.5 16.468a.497.497 0 0 1 .042-.17v-4.94c0-3.73 2.811-6.81 6.457-7.292V4ZM2.358 17H10a.5.5 0 0 0 0-1H2.571v-4.642C2.571 7.855 5.45 5.015 9 5.015c3.55 0 6.429 2.84 6.429 6.343v5.244c0 .11.036.217.102.305l1.44 1.217v1.861H1.03v-1.86L2.358 17ZM9.5 23a2.501 2.501 0 0 1-2.45-2h4.9a2.501 2.501 0 0 1-2.45 2Zm2-6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5Z"
        fill={`var(--glz-color-${type}-tone-1)`}
        fillRule="evenodd"
      />
      <path d="M19.5 8.5a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill={`var(--glz-color-${type}-tone-4)`} />
      <path
        clipRule="evenodd"
        d="M15 2.5a.5.5 0 0 0 1 0v-2a.5.5 0 0 0-1 0v2Zm.5 2.5a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7ZM11 8.5a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0Zm5.5 1H16V7.006a.519.519 0 0 0-.05-.224.498.498 0 0 0-.657-.237.497.497 0 0 0-.188.148l-.007.01-.748.997a.5.5 0 0 0 .65.733V9.5h-.5a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1Zm4.5-1a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5Zm-1.61-4.596a.5.5 0 0 0 .706.707l1.415-1.414a.5.5 0 0 0-.708-.707L19.39 3.904Z"
        fill={`var(--glz-color-${type}-tone-1)`}
        fillRule="evenodd"
      />
    </svg>
  );
};

export { BellIcon };
