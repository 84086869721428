/* eslint-disable react-hooks/rules-of-hooks */
import debounce from 'ati-utils/debounce';
import { LegacyRef, useEffect, useRef, useState } from 'react';

type IntersectionOptions = {
  root?: null | HTMLElement;
  rootMargin?: string;
  threshold?: number;
};

const useIntersection = (
  options: IntersectionOptions,
): [LegacyRef<HTMLDivElement> | null, IntersectionObserverEntry | null] => {
  const { root = null, rootMargin, threshold = 0 } = options;

  if (typeof window === 'undefined' || !window.IntersectionObserver) return [null, null];

  const [entry, updateEntry] = useState({} as IntersectionObserverEntry);
  const [node, setNode] = useState(null);

  const updater = debounce(([observableEntry]) => {
    updateEntry(observableEntry);
  }, 100);

  const observer = useRef(
    new window.IntersectionObserver(updater, {
      root,
      rootMargin,
      threshold,
    }),
  );

  useEffect(() => {
    const { current: currentObserver } = observer;
    currentObserver.disconnect();

    if (node) currentObserver.observe(node);

    return () => currentObserver.disconnect();
  }, [node]);

  return [setNode as LegacyRef<HTMLDivElement>, entry];
};

export { useIntersection };
