import Popover from '@components/popover';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { atiDriverApp } from 'constants/pages';
import { useStore } from 'hooks/useStore';

import { AppGalleryIcon } from './icons/AppGalleryIcon';
import { AppStoreIcon } from './icons/AppStoreIcon';
import { PlayMarketIcon } from './icons/PlayMarketIcon';
import { RuStoreIcon } from './icons/RuStoreIcon';

import css from './Promo.module.css';

type TPromoProps = {
  children: React.ReactNode;
  visible: boolean;
  onCloseClick: React.MouseEventHandler & ((event: KeyboardEvent) => void);
  onVisibilityChange: (isVisible: boolean) => void;
};

const Promo = ({ children, ...props }: TPromoProps) => {
  const {
    mobileDevices,
    app: { i18n },
  } = useStore();

  const hasApps = mobileDevices.availableDevices?.availableChannels.length;

  const HasAppsContent = (
    <Popover.Content>
      <div className={cn(css.info, { [css.info_hasApps]: hasApps })}>
        <img
          alt={i18n.filterPopup.appIconAlt}
          className={css.appIcon}
          height={40}
          src={atiDriverApp.appIconSrc}
          width={40}
        />

        <p className={cn('glz-p glz-is-size-3', css.disclaimer)}>
          <span>{i18n.filterPopup.notificationsToAllApps}</span>
          &nbsp;
          <strong>{i18n.filterPopup.notifiableApps}</strong>
        </p>
      </div>
    </Popover.Content>
  );

  const DownloadAppContent = (
    <>
      <Popover.Header>{i18n.mobileApp}</Popover.Header>

      <Popover.Content>
        <p className={cn('glz-p glz-is-size-3', css.disclaimer)}>{i18n.filterPopup.promoTitle}</p>

        <div className={css.apps}>
          <div className={css.info}>
            <img
              alt={i18n.filterPopup.appIconAlt}
              className={css.appIcon}
              height={24}
              src={atiDriverApp.appIconSrc}
              width={24}
            />

            <div>
              <p className={cn('glz-p glz-is-size-3 glz-is-bold', css.appName)}>{i18n.filterPopup.appName}</p>
              <p className={cn('glz-p glz-is-size-4', css.appDescription)}>{i18n.filterPopup.appDescription}</p>
            </div>
          </div>

          <div className={css.downloadLinks}>
            <a href={atiDriverApp.playmarket} rel="noreferrer" target="_blank">
              <PlayMarketIcon />
            </a>
            <a href={atiDriverApp.appstore} rel="noreferrer" target="_blank">
              <AppStoreIcon />
            </a>
            <a href={atiDriverApp.rustore} rel="noreferrer" target="_blank">
              <RuStoreIcon />
            </a>
            <a href={atiDriverApp.appgallery} rel="noreferrer" target="_blank">
              <AppGalleryIcon />
            </a>
          </div>
        </div>
      </Popover.Content>
    </>
  );

  const content = hasApps ? HasAppsContent : DownloadAppContent;

  return (
    <Popover
      isClosedOnOutsideClick
      classNames={{
        contentWrapper: cn(css.content, {
          [css.content_hasApps]: hasApps,
        }),
      }}
      content={content}
      {...props}
    >
      {children}
    </Popover>
  );
};

const connected = observer(Promo);

export { connected as Promo };
