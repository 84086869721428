import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC, useState } from 'react';

import { Checkbox } from 'components/Checkbox';
import { atiDriverApp } from 'constants/pages';
import { useStore } from 'hooks/useStore';

import { Promo } from './Promo';

import css from './MobileAppChannelCheckBox.module.css';

const MobileAppChannelCheckBox: FC = () => {
  const {
    app: { i18n },
    mobileDevices,
    savedFilters: { popup },
  } = useStore();

  const [isAppPromoVisible, setIsAppPromoVisible] = useState(false);

  if (!popup) return null;

  const handleTogglePopover = () => {
    setIsAppPromoVisible(!isAppPromoVisible);
  };

  const handleClosePopover = () => {
    setIsAppPromoVisible(false);
  };

  const isOnlyMobileChannelSelected = popup.channels.mobile.value && popup.channels.isOneChannelSelected;

  return (
    <Checkbox
      small
      checked={popup.channels.mobile.value}
      disabled={!mobileDevices.hasMobileAppInstalled || isOnlyMobileChannelSelected}
      tooltip={isOnlyMobileChannelSelected && i18n.subscriptions.channels.disabledTooltip}
      onChange={popup.channels.mobile.setValueFromCheckbox}
    >
      {i18n.filterPopup.inApp}

      <Promo visible={isAppPromoVisible} onCloseClick={handleClosePopover} onVisibilityChange={setIsAppPromoVisible}>
        <button className={cn('glz-link glz-is-medium', css.appName)} onClick={handleTogglePopover}>
          <img alt={i18n.filterPopup.appIconAlt} height={18} src={atiDriverApp.appIconSrc} width={18} />

          {i18n.filterPopup.appName}
        </button>
      </Promo>
    </Checkbox>
  );
};

const connected = observer(MobileAppChannelCheckBox);

export { connected as MobileAppChannelCheckBox };
