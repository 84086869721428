import { useEffect } from 'react';

import { useStore } from 'hooks/useStore';

import css from './BottomAdv.module.css';

const rotateAdv = () => {
  if (window !== undefined) {
    const helper = window['atiUserDataHelper'] || window['advHelper'];
    if (helper) {
      helper.getAdvManagerInstance()?.rotateAtiAdv(true);
    }
  }
};

const BottomAdv = () => {
  const {
    app: { searchResult },
    ui: { pagination, isMobile },
  } = useStore();

  useEffect(() => {
    rotateAdv();
  }, [searchResult, pagination.pages.search]);

  return (
    <div className={css.container}>
      {isMobile ? (
        <span ati-userdata-info="doc-preset=mobile_240_400" />
      ) : (
        <span ati-userdata-info="doc-preset=media_21" />
      )}
    </div>
  );
};

export { BottomAdv };
