import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';

import { editContactPopup } from 'constants/pages';
import { useStore } from 'hooks/useStore';
import { PopupIds } from 'store/UIStore/Popups';
import { interpolate } from 'utils/string/format';

import css from './EditContactPopup.module.css';

type TEditContactPopupMessage = {
  eventType: 'contactPopupClosed' | 'contactPopupSave';
  data: any | null;
};

const EditContactPopup: FC = () => {
  const {
    ui: { popups },
    app: { env },
    profile,
    savedFilters,
  } = useStore();

  useEffect(() => {
    function handlePostMessageEvent(event: MessageEvent) {
      let parsedEvent: TEditContactPopupMessage;

      try {
        parsedEvent = JSON.parse(event.data);
        if (!parsedEvent || !parsedEvent.eventType) {
          return;
        }
      } catch (e) {
        return;
      }

      switch (parsedEvent.eventType) {
        case 'contactPopupSave':
          // Ждем секунду в надежде, что в ответе уже будет сохраненный email
          setTimeout(() => {
            savedFilters.fetchSubscriptionData();
            popups.close(PopupIds.editContactPopup);
          }, 1000);
          break;
        case 'contactPopupClosed':
          popups.close(PopupIds.editContactPopup);
          break;
        default:
          break;
      }
    }

    window.addEventListener('message', handlePostMessageEvent, false);
    return () => window.removeEventListener('message', handlePostMessageEvent, false);
  }, [popups, savedFilters]);

  return (
    <iframe
      className={css.editContactPopup}
      src={interpolate(editContactPopup, { env, contactId: profile.contact?.id.toString() || '' })}
    />
  );
};

const connected = observer(EditContactPopup);

export { connected as EditContactPopup };
