import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';

import { ServiceLinks } from '../ServiceLinks';
import { Additional } from './Additional/Mobile';
import { Boards } from './Boards';
import { CarTypes } from './CarTypes';
import { CargoTypes } from './CargoTypes/CargoTypes';
import { Dates } from './Dates';
import { Dimensions } from './Dimensions';
import { ExtraParams } from './ExtraParams';
import { Firms } from './Firms';
import { LoadingTypes } from './LoadingTypes';
import { Payment } from './Payment';
import { Route } from './Route';
import { RouteParams } from './RouteParams';
import { DistanceRange } from './RouteParams/DistanceRange';
import { Volume } from './Volume';
import { Weight } from './Weight';

import css from './Filters.module.css';

const Filters = (props: { className?: string }) => {
  const {
    app: { i18n },
    filter: {
      boards: { commonBoard },
      routeParams: { isEllipseActive, isRouteLengthActive },
    },
    tabsData: { subscribedToSelectedFilter },
  } = useStore();

  const showFreeNotificationsTip = !commonBoard.isSelected.data && !subscribedToSelectedFilter;

  return (
    <div className={cn(css.container, props.className)}>
      <div className={cn(css.row, css.routeParams)}>
        <div className={css.header}>
          <div
            className={cn(css.headerRow, {
              [css.headerRow_border_bottom]: !isEllipseActive && !isRouteLengthActive,
            })}
          >
            <RouteParams />
            <ServiceLinks />
          </div>

          <DistanceRange className={css.desktopDistanceRange} />
        </div>
      </div>

      <div className={cn(css.row, css.row_pb, css.row_psides, css.geoRow)}>
        <Route />

        <div className={css.loadParams}>
          <Weight />
          <Volume />
        </div>
      </div>

      <div className={cn(css.row, css.row_darker, css.grid, css.additionalFilters)}>
        <Dates className={css.gridColumn} />
        <CarTypes className={css.gridColumn} />
        <CargoTypes className={css.gridColumn} />
        <LoadingTypes className={css.gridColumn} />
        <Payment className={css.gridColumn} />
        <ExtraParams className={css.gridColumn} />
      </div>

      <div className={cn(css.row, css.lastRow, css.grid, css.additionalFilters)}>
        <Dimensions className={cn(css.gridColumn, css.dimensionsColumn)} />
        <Boards className={css.gridColumn} />
        <Firms className={cn(css.gridColumn, css.firmsColumn)} />
      </div>

      <Additional.Button />

      <p className={cn(css.notification, { [css.isHidden]: !showFreeNotificationsTip })}>
        {i18n.app.enableNotificationsForBoards}
      </p>
    </div>
  );
};

const connected = observer(Filters);

export { connected as Filters };
