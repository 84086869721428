import Icon from '@components/icon';
import Select, { SelectOption } from '@components/select';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import { Field } from 'components/Field';
import { FollowedLoadsPagination } from 'components/FollowedLoadsPagination';
import { useStore } from 'hooks/useStore';
import { PrettyLoad } from 'layout/Search/PrettyLoad';
import { TabsContent } from 'layout/TabsContent';

import css from './ContentWithOffers.module.css';

const ContentWithOffers = () => {
  const {
    app: { i18n },
    view,
    followedLoads: { favorites, loadsWithOffers, loadsWithAuctions },
    ui: { pagination, handleTabChange },
  } = useStore();

  const callbacks = useMemo(
    () => ({
      hideLoads: () => {},
      selectLoads: loadsWithOffers.selectedLoads.toggleSelection,
      onRemoveLoadFromFavorites: favorites.updateLoads,
      onAddLoadToFavorites: favorites.updateLoads,
      onOfferChange: loadsWithOffers.updateLoads,
      onAuctionChange: loadsWithAuctions.updateLoads,
      onFavoritesFAQPopoverClose: favorites.handleFavoritesFAQPopoverClose,
      onFilterTabOpen: handleTabChange,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleItemsPerPageChange = (option: SelectOption) => {
    const newPage = Math.ceil(
      ((pagination.pages.onlyWithOffers - 1) * pagination.loadsWithOffersPerPage.data + 1) / option.value,
    );
    pagination.setCurrentPage(newPage);
    pagination.loadsWithOffersPerPage.setOption(option);
    loadsWithOffers.updateLoads();
  };

  if (loadsWithOffers.count === 0) {
    return (
      <TabsContent.NoContent
        className={css.noContent}
        desc={''}
        icon={<Icon color="var(--glz-color-neutral-tone-4)" name="ic_hands" size="xl" />}
        title={i18n.favorites.noContent.offers.title}
      />
    );
  }

  return (
    <div>
      <div className={css.heading}>
        <h2 className={cn(css.totalItems, 'glz-h glz-is-size-2')}>
          {view.pluralizedCount(loadsWithOffers.count, 'loads')}
        </h2>

        <FollowedLoadsPagination />
      </div>

      <div className={cn(css.actions, css.actions_top)}>
        <Field isRow labelClassName={css.sortLabel} labelText={i18n.app.showLines}>
          <Select
            className={css.sortSelect}
            dropdownWidth={{
              min: '100%',
              current: '100%',
            }}
            labelProperty="label"
            options={pagination.loadsWithOffersPerPage.options}
            usePortal={false}
            value={pagination.loadsWithOffersPerPage.option}
            onChange={handleItemsPerPageChange}
          />
        </Field>
      </div>

      <PrettyLoad
        callbacks={callbacks}
        currentPage={pagination.pages.onlyWithOffers}
        descriptor={{ isFavorites: true }}
        loads={loadsWithOffers.loads}
        updateLoad={loadsWithOffers.updateLoad}
      />

      {pagination.totalLoadsWithOffersPages > 1 && (
        <div className={cn(css.actions, css.actions_bottom)}>
          <FollowedLoadsPagination />

          <Field containerClassName={css.sortField} labelClassName={css.sortLabel} labelText={i18n.app.showLines}>
            <Select
              className={css.sortSelect}
              dropdownWidth={{
                min: '100%',
                current: '100%',
              }}
              labelProperty="label"
              options={pagination.loadsWithOffersPerPage.options}
              usePortal={false}
              value={pagination.loadsWithOffersPerPage.option}
              onChange={handleItemsPerPageChange}
            />
          </Field>
        </div>
      )}
    </div>
  );
};

const connected = observer(ContentWithOffers);

export { connected as ContentWithOffers };
