import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { useStore } from 'hooks/useStore';

import { CompactSummary } from './CompactSummary';
import { DetailedSummary } from './DetailedSummary';

import css from './FilterSummary.module.css';

const FilterSummary: FC = () => {
  const {
    savedFilters: { popup },
  } = useStore();

  if (!popup) return null;

  return (
    <div className={css.summary}>
      {popup.summary.showCompactSummary && <CompactSummary />}
      <DetailedSummary />
    </div>
  );
};

const connected = observer(FilterSummary);

export { connected as FilterSummary };
