import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';

import css from '../Prices.module.css';

const ExpectedNotificationsRateText = () => {
  const {
    app: { i18n },
    savedFilters: { popup },
    profile,
    view,
  } = useStore();

  if (!popup || !popup?.smsParams || !popup?.notificationsRate) return null;

  const { channels, notificationsRate, smsParams, hasSubscriptionTrialAvailable, expectedNotificationsCount } = popup;

  const smsRate = smsParams.rate || 0;
  const hasFreeNotifications = profile.hasUnlimitedNotificationsLicense || hasSubscriptionTrialAvailable;
  const hasPaidNotifications = !profile.hasUnlimitedNotificationsLicense && !hasSubscriptionTrialAvailable;
  const canShowSmsRate = channels.sms.value && Boolean(smsRate);
  const isAllChannelsWithoutSmsEnabled = channels.site.value || channels.email.value || channels.mobile.value;
  const isAllPaidChannelsWithoutSmsEnabled = hasPaidNotifications && isAllChannelsWithoutSmsEnabled;
  const isOnlySmsChannelEnabled = !channels.site.value && !channels.email.value && !channels.mobile.value;

  const notificationsRatePerDayText = view.atisCount(expectedNotificationsCount * notificationsRate);
  const smsPerDayRateText = view.atisCount(expectedNotificationsCount * smsRate);
  const totalRateText = `${view.atisCount(
    expectedNotificationsCount * smsRate + expectedNotificationsCount * notificationsRate,
  )} ${i18n.common.perDay}`;

  const showNotificationsPerDayText = isAllPaidChannelsWithoutSmsEnabled && Boolean(notificationsRatePerDayText);
  const showSmsPerDayText = canShowSmsRate && Boolean(smsPerDayRateText);
  const showTotalRateText = showNotificationsPerDayText && showSmsPerDayText;

  return (
    <>
      {(showNotificationsPerDayText || showSmsPerDayText) && <span>{i18n.subscriptions.inTotal}: </span>}
      {showNotificationsPerDayText && (
        <>
          <span className={cn({ [css.isBold]: !showTotalRateText })}>
            {notificationsRatePerDayText} {!channels.sms.value && i18n.common.perDay}
          </span>{' '}
          {i18n.subscriptions.perLoads}
        </>
      )}
      {showTotalRateText && ' + '}
      {showSmsPerDayText && (
        <>
          <span className={cn({ [css.isBold]: !showTotalRateText })}>
            {smsPerDayRateText} {(hasFreeNotifications || isOnlySmsChannelEnabled) && i18n.common.perDay}
          </span>{' '}
          {i18n.filterPopup.perSMS}
        </>
      )}
      {showTotalRateText && (
        <>
          {' '}
          = <strong>{totalRateText}</strong>
        </>
      )}
    </>
  );
};

const connected = observer(ExpectedNotificationsRateText);

export { connected as ExpectedNotificationsRateText };
