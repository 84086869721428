import OptionsSelector from '@components/options-selector';
import { observer } from 'mobx-react-lite';

import { MobileScreen } from 'components/MobileScreen';
import withCarTypes from 'hocs/withCarTypes';
import { useStore } from 'hooks/useStore';
import typeSelectorMobileCss from 'widgets/TypeSelector/TypeSelectorMobile.module.css';

const WrappedOptionsSelector = withCarTypes(OptionsSelector);

const Mobile = () => {
  const {
    dictionaries,
    dictionaries: { data },
    app: { i18n },
    filter: {
      truckType: { setTruckTypesFromBitSum, truckTypes, clearTruckTypes, addParentCarTypes },
    },
  } = useStore();

  const actions = {
    cancel: {
      onClick: clearTruckTypes,
      text: i18n.common.reset,
    },
  };

  const handleChange = (_values: string[], bitsum: string) => {
    setTruckTypesFromBitSum(bitsum);
  };

  const selectedValues = addParentCarTypes(dictionaries.carTypesWithChildren, truckTypes);

  return (
    <MobileScreen hasBackButton actions={actions} title={i18n.carTypes.label}>
      <WrappedOptionsSelector
        checkboxProps={{ size: 'medium' }}
        classNames={typeSelectorMobileCss}
        dictionary={data.carTypes}
        matchMedia="1px"
        selectedValues={selectedValues}
        /* @ts-ignore */
        onChange={handleChange}
      />
    </MobileScreen>
  );
};

const connected = observer(Mobile);

export { connected as Mobile };
