import { useMatchMedia } from '@ati/hooks';
import ButtonGroup from '@components/button-group';
import Select from '@components/select';
import { observer } from 'mobx-react-lite';

import { events } from 'constants/metrics';
import { useStore } from 'hooks/useStore';
import { TFollowedLoadsTabIds, followedLoadsTabIds } from 'store/UIStore/UIStore';
import { sendMetricsEvent } from 'utils/metrics';

import css from './ContentSwitcher.module.css';

const ContentSwitcher = () => {
  const {
    ui: { followedLoadsTab },
  } = useStore();

  const isMobile = useMatchMedia('(max-width: 679px)');

  const sendFollowedTabMetrics = (tab: TFollowedLoadsTabIds) => {
    if (tab === followedLoadsTabIds.onlyWithOffers) {
      sendMetricsEvent(events.selectedWithOffersTab);
    }

    if (tab === followedLoadsTabIds.onlyWithAuctions) {
      sendMetricsEvent(events.selectedWithAuctionsTab);
    }
  };

  const handleFollowedTabChange = (tab: TFollowedLoadsTabIds | typeof followedLoadsTab.option) => {
    if (typeof tab === 'string') {
      followedLoadsTab.setOptionByValue(tab);
      sendFollowedTabMetrics(tab);
    } else {
      followedLoadsTab.setOption(tab);
      sendFollowedTabMetrics(tab.value);
    }
  };

  return (
    <>
      {!isMobile && (
        <ButtonGroup<TFollowedLoadsTabIds>
          className={css.buttonGroup}
          options={followedLoadsTab.options}
          value={followedLoadsTab.data}
          onChange={handleFollowedTabChange}
        />
      )}

      {isMobile && (
        <Select
          className={css.select}
          dropdownWidth={{
            min: '100%',
            current: '100%',
          }}
          labelProperty="label"
          options={followedLoadsTab.options}
          usePortal={false}
          value={followedLoadsTab.option}
          onChange={handleFollowedTabChange}
        />
      )}
    </>
  );
};

const connected = observer(ContentSwitcher);

export { connected as ContentSwitcher };
