import { observer } from 'mobx-react-lite';

import { locales } from 'constants/common';
import { News } from 'layout/Media/News';
import { TSupportedLocales } from 'localization/types';

import css from './AsideMedia.module.css';

const AsideMedia = ({ locale }: { locale: TSupportedLocales }) => {
  return (
    <div className={css.container}>
      <div className={css.media}>
        {locale !== locales.en && (
          <span ati-userdata-info="doc-preset=loads_right_scroll;doc-extra-content=lastNews" min-screen-width="1560" />
        )}
        <News className="news-widget-aside" />
      </div>
    </div>
  );
};

const connected = observer(AsideMedia);

export { connected as AsideMedia };
