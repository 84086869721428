import { observer } from 'mobx-react-lite';

import { Column } from 'components/Column';
import { useStore } from 'hooks/useStore';

import { Manual } from './Manual';
import { Mobile } from './Mobile';
import { Options } from './Options';

interface DatesProps {
  className?: string;
}

const Dates = (props: DatesProps) => {
  const {
    app: { i18n },
    filter: {
      loadDate: { isDefault },
    },
    ui: { isAdvancedFiltersVisible, toggleIsAdvancedFiltersVisible },
  } = useStore();

  return (
    <Column
      className={props.className}
      header={i18n.dates.label}
      isContentVisible={isAdvancedFiltersVisible}
      isHighlighted={!isDefault}
      onClick={toggleIsAdvancedFiltersVisible}
    >
      <Options />
      <Manual />
    </Column>
  );
};

Dates.Mobile = Mobile;

const connected = observer(Dates);

export { connected as Dates };
