import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';

const ExpectedNotificationsCountText = () => {
  const {
    app: { i18n },
    savedFilters: { popup },
    profile,
    view,
  } = useStore();

  if (!popup || !popup?.smsParams || !popup?.notificationsRate) return null;

  const { channels, hasSubscriptionTrialAvailable, expectedNotificationsCount } = popup;

  const expectedNotificationsCountText = view.pluralizedCount(expectedNotificationsCount, 'notification');
  const isOnlyFreeNotificationsEnabled =
    !channels.sms.value && (hasSubscriptionTrialAvailable || profile.hasUnlimitedNotificationsLicense);

  return (
    <>
      {i18n.filterPopup.expectedCount}{' '}
      <strong>
        {expectedNotificationsCountText}
        {isOnlyFreeNotificationsEnabled && (
          <>
            {' '}
            <span>{i18n.common.free}</span>.
          </>
        )}
      </strong>
    </>
  );
};

const connected = observer(ExpectedNotificationsCountText);

export { connected as ExpectedNotificationsCountText };
