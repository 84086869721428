import Icon from '@components/icon';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import { Board } from 'components/Board';
import { Checkbox } from 'components/Checkbox';
import { Column } from 'components/Column';
import { DropdownToggle } from 'components/DropdownToggle';
import { QuestionTooltip } from 'components/QuestionTooltip';
import { locales } from 'constants/common';
import { auctionFaq, boardsFaq } from 'constants/pages';
import { useStore } from 'hooks/useStore';
import { Board as BoardType } from 'store/FilterStore/BoardsStore/Board';
import { interpolate } from 'utils/string/format';

import { Mobile } from './Mobile';

import css from './Boards.module.css';

const numberOfVisibleBoards = 4;

interface BoardsProps {
  className?: string;
}

const Boards = (props: BoardsProps) => {
  const {
    app: { i18n, env, locale },
    filter: {
      boards: {
        boards,
        withAuction,
        toggleIsAllSelected,
        isAllSelected,
        isDefault,
        isSelectedBoardsDefault,
        selectedBoards,
        isBoardsUnavailable,
      },
    },
    profile,
    ui: { isAdditionalFiltersVisible, toggleIsAdditionalFiltersVisible },
  } = useStore();

  const [active, setActive] = useState(false);

  const handleToggle = () => {
    setActive(!active);
  };

  const showBoardsFaqLink = locale === locales.ru && boards.length <= 1 && !isBoardsUnavailable.value;
  const showAuction = profile.data?.isUser;
  const visibleBoards = boards.slice(0, numberOfVisibleBoards);
  const hiddenBoards = boards.slice(numberOfVisibleBoards);

  const dropdownButtonText = isSelectedBoardsDefault
    ? i18n.boards.allBoards
    : interpolate(i18n.boards.totalSelectedBoards, { total: selectedBoards.length.toString() });
  const header = isBoardsUnavailable.value ? (
    <span className={css.errorHeader}>{i18n.boards.boardsUnavailable}</span>
  ) : (
    i18n.boards.label
  );

  return (
    <Column
      className={props.className}
      header={header}
      isContentVisible={isAdditionalFiltersVisible}
      isHighlighted={!isDefault}
      onClick={toggleIsAdditionalFiltersVisible}
    >
      <ul>
        {visibleBoards.map((board: BoardType) => {
          return (
            <li key={board.id} className={css.visibleBoard}>
              <Board board={board} />
            </li>
          );
        })}
      </ul>

      {boards.length > numberOfVisibleBoards && (
        <DropdownToggle
          classNames={{
            trigger: css.dropdownButton,
          }}
          dropdown={
            <ul className={cn(css.boardList, 'glz-dropdown-list')}>
              <Checkbox small checked={isAllSelected} className={css.selectAllButton} onChange={toggleIsAllSelected}>
                {isAllSelected ? i18n.boards.unselectAll : i18n.boards.selectAll}
              </Checkbox>
              {hiddenBoards.map((board: BoardType) => {
                return (
                  <li key={board.id} className={css.board}>
                    <Board board={board} className={css.dropdownBoard} />
                  </li>
                );
              })}
            </ul>
          }
          onToggle={handleToggle}
        >
          <DropdownToggle.DefaultButton gray active={active}>
            {dropdownButtonText}
          </DropdownToggle.DefaultButton>
        </DropdownToggle>
      )}

      {isBoardsUnavailable.value && (
        <p className={cn(css.errorMessage, 'glz-p glz-is-size-3 glz-has-text-red')}>
          {i18n.boards.boardsUnavailableDetails}
        </p>
      )}

      {showAuction && (
        <Checkbox small checked={withAuction.value} onChange={withAuction.setValueFromCheckbox}>
          {i18n.boards.auctionOnly}
          <a className="glz-link" href={interpolate(auctionFaq, { env })} rel="noreferrer" target="_blank">
            <QuestionTooltip text={i18n.boards.auctionTip} tooltipWidth={195} />
          </a>
        </Checkbox>
      )}

      {showBoardsFaqLink && (
        <a
          className={cn(css.link, 'glz-link', 'glz-is-underline', 'glz-is-gray')}
          href={interpolate(boardsFaq, { env })}
          rel="noreferrer"
          target="_blank"
        >
          {i18n.boards.boardsFaqLink}
          <Icon
            className={cn(css.icon, 'glz-after-icon')}
            color="currentColor"
            height={9}
            name="ic_external"
            width={9}
          />
        </a>
      )}
    </Column>
  );
};

Boards.Mobile = Mobile;

const connected = observer(Boards);

export { connected as Boards };
