module.exports = new Promise((resolve) => {
  const baseUrl = window.atiEnv.baseDomain;
  const url = 'https://files.' + baseUrl + '/cached/31536000/pretty-load/remoteEntry.js';

  const script = document.createElement('script');
  script.src = '' || url;

  const load = () => {
      const proxy = {
          get: (request) => window['PrettyLoad'].get(request),
          init: (arg) => {
              try {
                  return window['PrettyLoad'].init(arg);
              } catch (e) {
                  console.log('remote container already initialized');
              }
          },
      };
      resolve(proxy);
  };

  script.onload = load;
  script.onerror = () => {
      const fallbackScript = document.createElement('script');
      fallbackScript.src = url;
      fallbackScript.onload = load;
      fallbackScript.onerror = () => {
          const fallbackScriptFinal = document.createElement('script');
          fallbackScriptFinal.src = url;
          fallbackScriptFinal.onload = load;
          document.head.appendChild(fallbackScriptFinal);
      }
      document.head.appendChild(fallbackScript);
  };

  document.head.appendChild(script);
});