import { zIndexSystem } from '@ati/uti';
import Checkbox from '@components/checkbox';
import Icon from '@components/icon';
import IconWithActive from '@components/icon-with-active';
import Tooltip from '@components/tooltip';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';

import css from './CollectivityToggle.module.css';

const CollectivityToggle = () => {
  const {
    app: { i18n },
    savedFilters: { popup },
  } = useStore();

  if (!popup) return null;

  const handleIsCollectiveChange = () => {
    popup.isCollective.setData(!popup.isCollective.value);
  };

  const tooltipContent = !popup.hasRightsToEditCommonFilter
    ? i18n.filterPopup.warnings.noRightsToEditCommonFilter
    : i18n.filterPopup.warnings.disableIsCollective;
  const needToShowTooltip =
    !popup.hasRightsToEditCommonFilter || (popup.isCollectiveDisabled && !popup.isCollectiveDisabledWithWarning);
  const needToDisableCheckbox =
    !popup.hasRightsToEditCommonFilter || popup.isCollectiveDisabled || popup.isCollectiveDisabledWithWarning;

  return (
    <>
      <div className={css.container}>
        <Checkbox
          checked={popup.isCollectiveFilter}
          disabled={needToDisableCheckbox}
          onChange={handleIsCollectiveChange}
        >
          {i18n.filterPopup.toAll}
        </Checkbox>

        {needToShowTooltip && (
          <Tooltip
            classNames={{ wrapper: css.wrapper }}
            content={tooltipContent}
            maxWidth={256}
            placement="top"
            trigger="mouseenter focus"
            zIndex={{ tip: zIndexSystem.abovePopup }}
          >
            <IconWithActive
              activeIcon={<Icon color="var(--glz-color-primary)" height={14} name="ic_information-solid" width={14} />}
              className={css.icon}
              defaultIcon={
                <Icon color="var(--glz-color-neutral-tone-3)" height={14} name="ic_information" width={14} />
              }
            />
          </Tooltip>
        )}
      </div>

      {popup.isCollectiveDisabledWithWarning && (
        <p className={cn('glz-p glz-is-size-3', css.warning)}>{i18n.filterPopup.warnings.disableIsCollective}</p>
      )}
    </>
  );
};

const connected = observer(CollectivityToggle);

export { connected as CollectivityToggle };
