import Button from '@components/button';
import Popup from '@components/popup';

interface IConfirmationPopup {
  className?: string;
  title: string;
  text?: string;
  buttonText: string;
  cancelText?: string;
  isPopupActionLoading: boolean;
  onClose: () => void;
  onCancel?: () => void;
  onConfirm: Function;
}

const ConfirmationPopup = (props: IConfirmationPopup) => {
  const { title, text, buttonText, cancelText, isPopupActionLoading, onClose, onConfirm, className, onCancel } = props;

  return (
    <Popup className={className} size="small" onClose={onClose}>
      <Popup.Header>{title}</Popup.Header>

      {Boolean(text) && <Popup.Content>{text}</Popup.Content>}

      <Popup.Footer theme="white">
        <Popup.ActionWrapper type="main">
          <Button disabled={isPopupActionLoading} loading={isPopupActionLoading} theme="primary" onClick={onConfirm}>
            {buttonText}
          </Button>
        </Popup.ActionWrapper>

        {cancelText && (
          <Popup.ActionWrapper type="cancel">
            <button className="glz-link glz-is-large glz-is-primary" onClick={onCancel}>
              {cancelText}
            </button>
          </Popup.ActionWrapper>
        )}
      </Popup.Footer>
    </Popup>
  );
};

export { ConfirmationPopup };
