import cn from 'clsx';
import React from 'react';

import { capitalize } from 'utils/string/format';

import { TitledLabel } from './TitledLabel';

import css from './Field.module.css';

interface IField {
  containerClassName?: string;
  isRow?: boolean;
  stretchContent?: boolean;
  margin?: string | number;
}

interface ILabel {
  labelText?: string;
  labelContent?: React.ReactNode;
  labelClassName?: string;
  labelBold?: boolean;
  labelHighlighted?: boolean;
  labelWidth?: number | string;
}

interface IContent {
  contentWidth?: number;
  contentColumn?: boolean;
  contentClassName?: string;
}

interface FieldProps extends IField, ILabel, IContent {
  children: React.ReactNode;
}

const Field = (props: FieldProps) => {
  const {
    containerClassName,
    isRow,
    stretchContent,
    margin,
    labelText,
    labelContent,
    labelClassName,
    labelBold,
    labelHighlighted,
    labelWidth,
    contentWidth,
    contentClassName,
    children,
  } = props;

  const containerClass = cn(css.container, containerClassName, {
    [css.container_isRow]: isRow,
  });

  const labelClass = cn(css.label, labelClassName, {
    [css.label_bold]: labelBold,
    [css.label_highlighted]: labelHighlighted,
  });

  const contentClass = cn(css.content, contentClassName, {
    [css.content_stretch]: stretchContent,
  });

  return (
    <div className={containerClass} style={{ margin }}>
      {(labelText || labelContent) && (
        <label className={labelClass} style={{ width: labelWidth }}>
          {capitalize(labelText || '')}
          {labelContent}
        </label>
      )}

      <div className={contentClass} style={{ width: contentWidth }}>
        {children}
      </div>
    </div>
  );
};

Field.TitledLabel = TitledLabel;

export { Field };
