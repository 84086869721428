import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';

import css from './styles.module.css';

const CompactViewButton = () => {
  const {
    ui: { isCompactPrettyLoad, setIsCompactPrettyLoad },
    app: { i18n },
  } = useStore();

  const handleClick = () => {
    setIsCompactPrettyLoad(!isCompactPrettyLoad);
  };

  return (
    <div className={css.container}>
      <span>{i18n.app.view}</span>
      <button
        className={cn(css.switchIcon, {
          [css.switchIcon_compact]: isCompactPrettyLoad,
        })}
        onClick={handleClick}
      />
    </div>
  );
};

const connected = observer(CompactViewButton);

export { connected as CompactViewButton };
