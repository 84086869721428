import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { tabIds } from 'constants/ui';
import { useStore } from 'hooks/useStore';

import css from './NewFavoritesTabInformer.module.css';

const NewFavoritesTabInformer = () => {
  const {
    ui,
    app: { i18n },
  } = useStore();

  const handleLinkClick = (e: React.MouseEvent) => {
    e.preventDefault();
    ui.setActiveTab(tabIds.favorites);
    ui.closeNewFavoritesTabPopover();
  };

  if (!ui.shouldShowNewFavoritesTabPopoverVisible) return null;

  return (
    <div className={cn('glz-shape glz-shape-with-shadow glz-has-background-light-orange', css.container)}>
      <p className={cn('glz-p glz-is-size-3', css.text)}>{i18n.favorites.informer}</p>

      <a className="glz-link glz-is-medium glz-is-primary" href={`/?tab=${tabIds.favorites}`} onClick={handleLinkClick}>
        {i18n.favorites.goToTab}
      </a>

      <button
        className={cn('glz-close glz-is-medium', css.closeButton)}
        onClick={ui.closeNewFavoritesTabPopover}
      ></button>
    </div>
  );
};

const connected = observer(NewFavoritesTabInformer);

export { connected as NewFavoritesTabInformer };
