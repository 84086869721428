const HistroryIcon = () => (
  <svg fill="none" height="42" viewBox="0 0 43 42" width="43" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M17.1259 10.5005V1.75004H8.1932C7.87294 1.75004 7.57893 1.9233 7.42668 2.2033L2.85582 10.5005H17.1259ZM18.8759 10.5005H33.1451L28.5743 2.2033C28.422 1.9233 28.128 1.75004 27.8077 1.75004H18.8759V10.5005ZM18.0122 -4.76837e-06H27.8095C28.7668 -4.76837e-06 29.6488 0.519759 30.109 1.35803L35.3924 10.9518C35.4747 11.1004 35.5092 11.2623 35.5009 11.42V18.3757C35.5009 18.8587 35.1089 19.2507 34.6259 19.2507C34.1429 19.2507 33.7509 18.8587 33.7509 18.3757V12.2505H2.25005V32.6806C2.25005 32.9956 2.50555 33.2511 2.82056 33.2511H18.8755C19.3585 33.2511 19.7505 33.6431 19.7505 34.1261C19.7505 34.6091 19.3585 35.0011 18.8755 35.0011H2.82056C1.54128 35.0011 0.5 33.9598 0.5 32.6806V11.3755V11.3747C0.49992 11.2828 0.514331 11.1902 0.544222 11.1002C0.562479 11.0452 0.586064 10.9925 0.614366 10.9429L5.89364 1.35978C6.3539 0.519759 7.23592 -4.76837e-06 8.1932 -4.76837e-06H17.9896L18.0009 -7.62939e-05L18.0122 -4.76837e-06ZM30.2508 38.5009C25.4259 38.5009 21.5005 34.5756 21.5005 29.7507C21.5005 24.9258 25.4259 21.0005 30.2508 21.0005C35.0756 21.0005 39.001 24.9258 39.001 29.7507C39.001 31.849 38.2586 33.7771 37.0227 35.2863L42.2445 40.5081C42.5857 40.8493 42.5857 41.4041 42.2445 41.7454C42.0747 41.9151 41.8507 42.0009 41.6267 42.0009C41.4027 42.0009 41.1787 41.9151 41.0072 41.7454L35.7853 36.5235C34.2763 37.7589 32.3486 38.5009 30.2508 38.5009ZM30.2508 22.7505C26.3902 22.7505 23.2506 25.8901 23.2506 29.7507C23.2506 33.6113 26.3902 36.7509 30.2508 36.7509C34.1114 36.7509 37.251 33.6113 37.251 29.7507C37.251 25.8901 34.1114 22.7505 30.2508 22.7505Z"
      fill="var(--glz-color-neutral-tone-3)"
      fillRule="evenodd"
    />
  </svg>
);

export { HistroryIcon };
