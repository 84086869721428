import { observer } from 'mobx-react-lite';

import { ConfirmationPopup } from 'components/ConfirmationPopup';
import { useStore } from 'hooks/useStore';
import { PopupIds } from 'store/UIStore/Popups';

import css from './FilterUnsubscribeConfirmationPopup.module.css';

const FilterUnsubscribeConfirmationPopup = () => {
  const {
    app: { i18n, loading },
    ui: { popups },
  } = useStore();

  if (!popups.filterUnsubscribeConfirmationPopup?.isOpened) return null;

  const handleClose = () => {
    popups.close(PopupIds.filterUnsubscribeConfirmationPopup);
  };

  const isDeleting = loading.deleteSubscription;
  const { title, buttons } = i18n.savedFilters.unsubscribeFilterPopup;

  return (
    <ConfirmationPopup
      buttonText={buttons.confirm}
      cancelText={buttons.cancel}
      className={css.confirmationPopup}
      isPopupActionLoading={isDeleting}
      title={title}
      onCancel={handleClose}
      onClose={handleClose}
      onConfirm={popups.filterUnsubscribeConfirmationPopup.payload.onConfirm}
    />
  );
};

const connected = observer(FilterUnsubscribeConfirmationPopup);

export { connected as FilterUnsubscribeConfirmationPopup };
