import Icon from '@components/icon';
import Tooltip from '@components/tooltip';

import { useStore } from 'hooks/useStore';
import { TSubscribedFilter } from 'store/Subscriptions/SubscribedFilter';

import { BellIcon } from './BellIcon';

import css from './MainIcon.module.css';

interface IMainIcon {
  filter: TSubscribedFilter;
}

const MainIcon = ({ filter }: IMainIcon) => {
  const {
    app: { i18n },
  } = useStore();

  if (filter.instance?.hasNotifications && filter.instance?.hasSubscription) {
    return (
      <span className={css.icon}>
        <BellIcon type="error" />
      </span>
    );
  }

  if (filter.subscription) {
    return (
      <Tooltip isWithoutWrapper content={i18n.subscriptions.subButton.newTooltip} maxWidth={180}>
        <span className={css.icon}>
          <BellIcon type="success" />
        </span>
      </Tooltip>
    );
  }

  return (
    <span className={css.icon}>
      <Icon color="var(--glz-color-neutral-tone-3)" name="ic_lens" size="m" />
    </span>
  );
};

export { MainIcon };
