import OptionsSelector from '@components/options-selector';
import { observer } from 'mobx-react-lite';

import { MobileScreen } from 'components/MobileScreen';
import withLoadingTypes from 'hocs/withLoadingTypes';
import { useStore } from 'hooks/useStore';
import typeSelectorMobileCss from 'widgets/TypeSelector/TypeSelectorMobile.module.css';

const WrappedOptionsSelector = withLoadingTypes(OptionsSelector);

const Mobile = () => {
  const {
    dictionaries: { data },
    app: { i18n },
    filter: {
      loadingType: { loadingTypes, clearLoadingTypes, setLoadingTypes },
    },
  } = useStore();

  const actions = {
    cancel: {
      onClick: clearLoadingTypes,
      text: i18n.common.reset,
    },
  };

  const handleChange = (values: string[]) => {
    setLoadingTypes(values);
  };

  return (
    <MobileScreen hasBackButton actions={actions} title={i18n.loadingTypes.label}>
      <WrappedOptionsSelector
        checkboxProps={{ size: 'medium' }}
        classNames={typeSelectorMobileCss}
        /* @ts-ignore */
        dictionary={data.loadingTypes}
        matchMedia="1px"
        selectedValues={loadingTypes}
        /* @ts-ignore */
        onChange={handleChange}
      />
    </MobileScreen>
  );
};

const connected = observer(Mobile);

export { connected as Mobile };
