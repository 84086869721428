import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';

import { SubscriptionMessage } from '../SubscriptionMessage';

const UnlimitedNotificationsLicenseMessage = () => {
  const {
    app: { i18n },
    profile,
  } = useStore();

  const shouldShowMessage = profile.hasUnlimitedNotificationsLicense;

  if (shouldShowMessage) {
    return <SubscriptionMessage>{i18n.filterPopup.unlimitedActive}</SubscriptionMessage>;
  }

  return null;
};

const connected = observer(UnlimitedNotificationsLicenseMessage);

export { connected as UnlimitedNotificationsLicenseMessage };
