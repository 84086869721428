import Popover from '@components/popover';
import cn from 'clsx';
import React, { useState } from 'react';

import css from './ActionPopover.module.css';

export interface ActionPopoverProps {
  title: string;
  primaryAction: string;
  alternateAction: string;
  children: React.ReactNode;
  onConfirm: () => void;
  onCancel?: () => void;
}

const ActionPopover = ({
  children,
  title,
  primaryAction,
  alternateAction,
  onConfirm,
  onCancel,
}: ActionPopoverProps) => {
  const [visible, setVisible] = useState(false);

  const handleOpen = () => {
    if (!visible) setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };

  const hadleConfirm = () => {
    onConfirm();
    handleClose();
  };

  const content = (
    <>
      <Popover.Header className={css.title}>{title}</Popover.Header>

      <Popover.Footer className={css.actions}>
        <Popover.ActionWrapper>
          <button className="glz-button glz-is-small glz-is-primary" onClick={hadleConfirm}>
            {primaryAction}
          </button>
        </Popover.ActionWrapper>

        <Popover.ActionWrapper>
          <button className={cn(css.alternateAction, 'glz-link glz-is-large')} onClick={onCancel || handleClose}>
            {alternateAction}
          </button>
        </Popover.ActionWrapper>
      </Popover.Footer>
    </>
  );

  return (
    <button onClick={handleOpen}>
      <Popover
        isClosedOnOutsideClick
        className={css.popover}
        content={content}
        visible={visible}
        onCloseClick={handleClose}
        onVisibilityChange={setVisible}
      >
        {children}
      </Popover>
    </button>
  );
};

export { ActionPopover };
