const contrastTreshold = 186;

export function getContastColor(hex: string) {
  hex = hex.slice(1);

  /* Если пришел сокращенный hex удваиваем значения. */
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map(c => c + c)
      .join('');
  }

  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  /*  https://stackoverflow.com/a/3943023/112731 */
  return r * 0.299 + g * 0.587 + b * 0.114 > contrastTreshold ? getCSSVarValue('dark') : getCSSVarValue('white');
}

export const getCSSVarValue = (varName: string) =>
  getComputedStyle(document.documentElement).getPropertyValue(`--${varName}`);
