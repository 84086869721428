import { observer } from 'mobx-react-lite';

import { OptionsList } from 'components/OptionsList';
import { useStore } from 'hooks/useStore';

import { Rate } from './Rate';

const Content = () => {
  const {
    options,
    filter: { extraParams },
  } = useStore();

  return (
    <>
      <OptionsList
        options={options.getPaymentTypes()}
        selectedValues={extraParams.params}
        valueProperty="id"
        onChange={extraParams.setParam}
      />

      <Rate />
    </>
  );
};

const connected = observer(Content);

export { connected as Content };
