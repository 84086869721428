import { observer } from 'mobx-react-lite';

import { Column } from 'components/Column';
import { useStore } from 'hooks/useStore';

import { Content } from './Content';
import { Mobile } from './Mobile';

interface PaymentProps {
  className?: string;
}

const Payment = (props: PaymentProps) => {
  const {
    app: { i18n },
    ui: { isAdvancedFiltersVisible, toggleIsAdvancedFiltersVisible },
    filter: { isPaymentDefault },
  } = useStore();

  return (
    <Column
      className={props.className}
      header={i18n.payment.label}
      isContentVisible={isAdvancedFiltersVisible}
      isHighlighted={!isPaymentDefault}
      onClick={toggleIsAdvancedFiltersVisible}
    >
      <Content />
    </Column>
  );
};

Payment.Mobile = Mobile;

const connected = observer(Payment);

export { connected as Payment };
