import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { locales } from 'constants/common';
import { cargosCreate, insuranceLink, traceLink } from 'constants/pages';
import { useStore } from 'hooks/useStore';
import { interpolate } from 'utils/string/format';

import css from './ServiceLinks.module.css';

const ServiceLinks = () => {
  const {
    app: { i18n, env, locale },
  } = useStore();

  return (
    <div className={css.container}>
      <a
        className={cn(css.link, 'glz-link', 'glz-is-primary')}
        href={interpolate(cargosCreate, { env })}
        rel="noreferrer"
        target="_blank"
      >
        {i18n.serviceLinks.addLoad}
      </a>

      <a
        className={cn(css.link, 'glz-link', 'glz-is-gray')}
        href={interpolate(traceLink, { env })}
        rel="noreferrer"
        target="_blank"
      >
        {i18n.serviceLinks.getDirections}
      </a>

      {locale === locales.ru && (
        <a
          className={cn(css.link, 'glz-link', 'glz-is-primary')}
          href={interpolate(insuranceLink, { env })}
          rel="noreferrer"
          target="_blank"
        >
          {i18n.serviceLinks.insurance}
        </a>
      )}
    </div>
  );
};

const connected = observer(ServiceLinks);

export { connected as ServiceLinks };
