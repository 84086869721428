import Button from '@components/button';
import Link from '@components/link';
import Popup from '@components/popup';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

import { useStore } from 'hooks/useStore';

import css from './MobileScreen.module.css';

type MobileScreenProps = {
  children: ReactNode;
  title: string;
  hasBackButton?: boolean;
  actions?: Actions;
  mainActionAsGoBack?: boolean;
};

type Actions = {
  [key in 'main' | 'second' | 'cancel']?: {
    onClick: () => void;
    text: string;
    buttonProps?: React.ComponentProps<typeof Button>;
  };
};

const MobileScreen = ({ children, title, hasBackButton, actions, mainActionAsGoBack = true }: MobileScreenProps) => {
  const {
    ui: {
      mobileScreens: { closeScreens, goBack },
    },
    app: { i18n },
  } = useStore();

  return (
    <Popup size="medium" onClose={closeScreens}>
      <Popup.Header backLinkContent={hasBackButton && i18n.common.back} onBackClick={goBack}>
        {title}
      </Popup.Header>
      <Popup.Content>{children}</Popup.Content>

      <Popup.Footer className={css.footer}>
        {(mainActionAsGoBack || Boolean(actions?.main)) && (
          <Popup.ActionWrapper type="main">
            <Button
              fullWidth
              outlined
              theme="primary"
              onClick={mainActionAsGoBack ? goBack : actions?.main?.onClick}
              {...actions?.main?.buttonProps}
            >
              {actions?.main?.text || i18n.common.confirm}
            </Button>
          </Popup.ActionWrapper>
        )}

        {Boolean(actions?.second) && (
          <Popup.ActionWrapper type="second">
            <Button fullWidth onClick={actions?.second?.onClick}>
              {actions?.second?.text}
            </Button>
          </Popup.ActionWrapper>
        )}

        {Boolean(actions?.cancel) && (
          <Popup.ActionWrapper type="cancel">
            <Link onClick={actions?.cancel?.onClick}>{actions?.cancel?.text}</Link>
          </Popup.ActionWrapper>
        )}
      </Popup.Footer>
    </Popup>
  );
};

const connected = observer(MobileScreen);

export { connected as MobileScreen };
