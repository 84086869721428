import Icon from '@components/icon';
import Tooltip from '@components/tooltip';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import { DropdownToggle } from 'components/DropdownToggle';
import { useStore } from 'hooks/useStore';
import { Geo, Geo as GeoInstance } from 'store/FilterStore/Geo';
import { PopupIds } from 'store/UIStore/Popups';
import { GeoListType } from 'types/cargosApp/Geo';

import { CreateAction } from './CreateAction';
import { GroupsList } from './GroupsList';

import css from './GeoList.module.css';

const GeoItem = ({ item, point }: { item: GeoListType; point: Geo }) => {
  const { ui, app } = useStore();

  const elements = item.elements.map(elem => {
    const title = elem.city_name || elem.region_name || '';
    const country = elem.country_name || '';

    return <div key={elem.id}>{[title, country].filter(Boolean).join(', ')} </div>;
  });

  const hasListContent = Boolean(elements.length);

  const handleListEdit = (event: React.MouseEvent) => {
    event.stopPropagation();

    ui.popups.open(PopupIds.geoListsPopup, {
      stage: 'watch',
      list: item,
      point,
    });
  };

  return (
    <div className={css.geo}>
      <Tooltip
        classNames={{ wrapper: css.tooltipWrapper, content: css.listConentTip }}
        content={hasListContent ? elements : app.i18n.route.geoList.isEmptyList}
        offset={[0, 40]}
        placement="right"
      >
        <div className={cn(css.geoName, { [css.geoName_disabled]: !hasListContent })}>{item.name}, </div>
        <div className={cn(css.geoCount, { [css.geoCount_disabled]: !hasListContent })}>{elements.length}</div>
      </Tooltip>

      {!item.is_global && (
        <Tooltip isWithoutWrapper content={app.i18n.route.geoList.editTip}>
          <button className={css.editButton} onClick={handleListEdit}>
            <Icon color="var(--glz-color-neutral-tone-4)" height={12} name="ic_edit" width={12} />
          </button>
        </Tooltip>
      )}
    </div>
  );
};

const ConnectedGeoItem = observer(GeoItem);

const renderConnectedGeoItem = (item: GeoListType, point: Geo) => {
  return <ConnectedGeoItem item={item} point={point} />;
};

interface IGeoList {
  point: GeoInstance;
}

const GeoList = ({ point }: IGeoList) => {
  const {
    app: { i18n },
    filter,
    dictionaries: { data },
  } = useStore();

  const [active, setActive] = useState(false);

  const handleToggle = () => {
    setActive(!active);
  };

  const disabled = filter.routeParams.isEllipseActive;

  const handleSelect = (item: GeoListType) => {
    if (!item.elements.length) return;

    point.handleListChange(item);
    setActive(false);
  };

  return (
    <div className={cn({ 'glz-tooltip glz-is-position-right': disabled })}>
      {disabled && (
        <span className="glz-tooltiptext" style={{ width: '225px' }}>
          {i18n.route.geoList.disabledByEllipse}
        </span>
      )}
      <DropdownToggle
        active={active}
        additionalAction={<CreateAction point={point} />}
        classNames={{
          additionalAction: css.additionalAction,
        }}
        disabled={disabled}
        dropdown={
          <>
            <GroupsList
              data={[]}
              groups={[data.privateGeoLists, data.publicGeoLists]}
              renderItem={(item: GeoListType) => renderConnectedGeoItem(item, point)}
              onSelect={handleSelect}
            />
          </>
        }
        onToggle={handleToggle}
      >
        <DropdownToggle.DefaultButton active={active} disabled={disabled}>
          <span className={css.chooseListFirstPart}>{i18n.route.geoList.chooseList.firstPart}</span>{' '}
          <span className={css.chooseListSecondPart}>{i18n.route.geoList.chooseList.secondPart}</span>
        </DropdownToggle.DefaultButton>
      </DropdownToggle>
    </div>
  );
};

const connected = observer(GeoList);

export { connected as GeoList };
