import { withRestriction } from '@ati/hocs';
import Input from '@components/input';
import React, { ChangeEvent } from 'react';

const RestrictedInputUI = withRestriction<TInputProps>(Input);

type TInputProps = React.ComponentProps<typeof Input>;

type Props = TRescrictedInputProps &
  Omit<TRestrictedInputUIProps, 'autoFocus' | 'disabled' | 'onChange' | 'onBlur'> & {
    autoFocus?: boolean;
    disabled?: boolean;
  };

type TRescrictedInputProps = {
  customPermit?: Array<keyof typeof customPermissions>;
  customRestrict?: Array<keyof typeof customRestrictions>;
  clearZeroOnBlur?: boolean;
  onValueChange?: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  onInputBlur?: (event: FocusEvent, value: string) => void;
};

type TRestrictedInputUIProps = React.ComponentProps<typeof RestrictedInputUI>;

const customRestrictions = {
  excessLeadingZeros: /^0+(?=\d)/,
  leadingSymbols: /^\.|^,/,
  leadingZero: /^0/,
  floatNumbers: /,|\./,
};

const customPermissions = {
  onlyNumbers: /^\d*$|^[-+]\d*$/,
};

const endsWithPointlessSymbol = /\.$|,$/;

function RestrictedInput(props: Props) {
  const { customPermit = [], customRestrict = [], onValueChange, onInputBlur, clearZeroOnBlur, ...restProps } = props;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isRestricted = customRestrict.some(rest => customRestrictions[rest].test(event?.target?.value));

    const isAllowed = customPermit.every(rule => customPermissions[rule].test(event?.target?.value));

    if (isRestricted || !isAllowed) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }

    if (typeof onValueChange === 'function') {
      onValueChange(event, event?.target?.value);
    }

    return false;
  };

  const handleBlur = (event: any) => {
    if (!event.target) return;

    if (endsWithPointlessSymbol.test(event.target?.value)) {
      event.target.value = event.target.value.replace(endsWithPointlessSymbol, '');
      if (typeof onValueChange === 'function') {
        onValueChange(event, event?.target?.value);
      }
    }

    if (clearZeroOnBlur && !Number(props.value) && typeof onValueChange === 'function') {
      event.target.value = '';
      onValueChange(event, event.target.value);
    }

    if (typeof onInputBlur === 'function') {
      onInputBlur(event, event?.target?.value);
    }
  };

  return (
    <RestrictedInputUI
      {...restProps}
      classNames={{ ...props.classNames }}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
}

export { RestrictedInput };
