import cn from 'clsx';
import type { CSSProperties, FC } from 'react';

import css from './Stack.module.css';

/**
 * Компонент для отображения элементов по вертикали с возможностью указать расстояние между элементами
 */
export const Stack: FC<TStackProps> = ({ gap, className, children }) => {
  const style = { '--stack-gap': `${gap}px` } as CSSProperties;

  return (
    <div className={cn(css.stack, className)} style={style}>
      {children}
    </div>
  );
};

export type TStackProps = {
  className?: string;
  gap?: number;
};
