import Icon from '@components/icon';
import IconWithActive from '@components/icon-with-active';
import Link from '@components/link';
import Popover from '@components/popover';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { notificationsLimitFAQ } from 'constants/pages';
import { useStore } from 'hooks/useStore';
import { interpolate } from 'utils/string/format';

import css from './Footer.module.css';

const NotificationInfoPopover = () => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const handleTogglePopover = () => {
    setIsPopoverVisible(!isPopoverVisible);
  };

  const handleClosePopover = () => {
    setIsPopoverVisible(false);
  };

  const {
    app: { i18n, env },
  } = useStore();

  return (
    <Popover
      isClosedOnOutsideClick
      content={
        <>
          <Popover.Header withoutContent type="small">
            {i18n.savedFilters.notificationsInfo.popover.desc}
          </Popover.Header>
          <Popover.Footer>
            <Popover.ActionWrapper>
              <Link href={interpolate(notificationsLimitFAQ, { env })} rel="noopener noreferrer" target="_blank">
                {i18n.savedFilters.notificationsInfo.popover.link}
              </Link>
            </Popover.ActionWrapper>
          </Popover.Footer>
        </>
      }
      isAdaptive={false}
      size="small"
      visible={isPopoverVisible}
      onCloseClick={handleClosePopover}
      onVisibilityChange={setIsPopoverVisible}
    >
      <IconWithActive
        activeIcon={<Icon color="var(--glz-color-primary)" height={14} name="ic_question-solid" width={14} />}
        classNames={{
          wrapper: css.iconWrapper,
        }}
        defaultIcon={<Icon color="var(--glz-color-neutral-tone-4)" height={14} name="ic_question-out" width={14} />}
        onClick={handleTogglePopover}
      />
    </Popover>
  );
};

const connected = observer(NotificationInfoPopover);

export { connected as NotificationInfoPopover };
