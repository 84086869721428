import Tooltip from '@components/tooltip';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useStore } from 'hooks/useStore';
import { TSubscribedFilter } from 'store/Subscriptions/SubscribedFilter';
import { PopupIds } from 'store/UIStore/Popups';
import { withConditionalWrapping } from 'utils/hocs/withConditionalWrapping';
import { insertNoBreakAfterShortWords } from 'utils/string/format';

import { Actions } from './Actions';
import { Counters } from './Counters';
import { MainIcon } from './MainIcon';

import css from './Card.module.css';

const ConditionalTooltip = withConditionalWrapping(Tooltip);

export type Item = {
  id: number | string;
  name: string;
  details?: string;
  subscription?: any;
};

interface ICard<T> {
  item: T;
  isActive: boolean;
  onItemClick: (item: T) => void;
  onItemDelete?: (item: T) => void;
  onItemEdit?: (item: T) => void;
  onItemSubscribe?: (item: T) => void;
  onItemUsubscribe?: (item: T) => void;
}

const lineMaxLength = 34;

const formatContent = (str: string, maxLength: number): [string, boolean] => {
  const isContentTooLong = str.trim().length > maxLength;
  const content = isContentTooLong ? str?.substring(0, maxLength).trim() + '...' : str;

  return [content, isContentTooLong];
};

const Card = <T extends TSubscribedFilter>(props: ICard<T>) => {
  const { item, onItemClick, onItemDelete, onItemSubscribe, onItemUsubscribe, onItemEdit } = props;
  const {
    app: { i18n },
    ui: { isActiveSavedFiltersTab, popups },
  } = useStore();

  const { errorDescription, hasError } = item.instance || { errorDescription: '' };
  const hasSubscription = item.instance?.hasSubscription;

  const maxNameLength = errorDescription ? lineMaxLength : lineMaxLength * 2;
  const [name, isNameShortened] = formatContent(item.name, maxNameLength);
  const [errorDesc, isErrorDescShortened] = formatContent(errorDescription, lineMaxLength);

  const handleItemClick = () => {
    onItemClick(item);
  };

  const handleItemDelete = async () => {
    onItemDelete && (await onItemDelete(item));
    popups.close(PopupIds.filterDeleteConfirmationPopup);
  };

  const handleItemUnsubscribe = async () => {
    onItemUsubscribe && (await onItemUsubscribe(item));
    popups.close(PopupIds.filterUnsubscribeConfirmationPopup);
  };

  const deleteFilter = () =>
    popups.open(PopupIds.filterDeleteConfirmationPopup, {
      onConfirm: handleItemDelete,
    });

  const unsubscribeFilter = () =>
    popups.open(PopupIds.filterUnsubscribeConfirmationPopup, {
      onConfirm: handleItemUnsubscribe,
    });

  const handleItemEdit = () => {
    onItemEdit && onItemEdit(item);
  };

  const handleItemSubscribe = () => {
    onItemSubscribe && onItemSubscribe(item);
  };

  const actions = [
    {
      id: 'editFilter',
      onClick: handleItemEdit,
      icon: 'ic_bell',
      text: isActiveSavedFiltersTab ? i18n.app.editFilter : i18n.app.saveFilter,
      hidden: !onItemEdit,
    },
    {
      id: 'subscribeFilter',
      onClick: handleItemSubscribe,
      icon: 'ic_play',
      text: i18n.app.subscribeFilter,
      hidden: !onItemSubscribe || hasSubscription,
    },
    {
      id: 'deleteFilter',
      onClick: deleteFilter,
      icon: 'ic_delete',
      text: i18n.app.deleteFilter,
      hidden: !onItemDelete || hasSubscription,
    },
    {
      id: 'unsubscribeFilter',
      onClick: unsubscribeFilter,
      icon: 'ic_close',
      text: i18n.app.unsubscribeFilter,
      hidden: !hasSubscription,
    },
  ];

  const hasActions = actions.some(action => !action.hidden);

  return (
    <div
      className={cn(css.container, {
        [css.container_subscribed]: hasSubscription,
        [css.container_error]: hasError,
        [css.container_noActions]: !hasActions,
      })}
      onClick={handleItemClick}
    >
      {isActiveSavedFiltersTab && <MainIcon filter={item} />}
      {isActiveSavedFiltersTab && <Counters filter={item} />}

      <span className={css.content}>
        <ConditionalTooltip content={item.name} maxWidth={334} wrapIf={isNameShortened}>
          <span className={cn(css.name, { [css.name_oneLine]: hasError })}>{insertNoBreakAfterShortWords(name)}</span>
        </ConditionalTooltip>

        {Boolean(errorDescription) && (
          <ConditionalTooltip content={errorDescription} maxWidth={334} wrapIf={isErrorDescShortened}>
            <span className={css.errorDesc}>{errorDesc}</span>
          </ConditionalTooltip>
        )}
      </span>

      {hasActions && <Actions actions={actions} filter={item} />}
    </div>
  );
};

const connected = observer(Card);

export { connected as Card };
