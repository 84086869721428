import React, { ComponentType } from 'react';

type WithConditionalWrappingProps = { wrapIf: boolean };

export function withConditionalWrapping<T extends object>(Component: ComponentType<T>) {
  const WrapperComponent: ComponentType<T & WithConditionalWrappingProps> = ({ wrapIf = true, ...props }) => {
    if (!wrapIf) {
      // github.com/DefinitelyTyped/DefinitelyTyped/issues/33006
      return <>{props.children}</>;
    }

    return <Component {...(props as T)}>{props.children}</Component>;
  };

  return WrapperComponent;
}
