import { observer } from 'mobx-react-lite';

import { MobileScreen } from 'components/MobileScreen';
import { useStore } from 'hooks/useStore';

import { Manual } from '../Manual';
import { Options } from '../Options';

const Mobile = () => {
  const {
    app: { i18n },
    filter: { resetDate },
  } = useStore();

  const actions = {
    cancel: {
      onClick: resetDate,
      text: i18n.common.reset,
    },
  };

  return (
    <MobileScreen hasBackButton actions={actions} title={i18n.dates.label}>
      <Options />
      <Manual />
    </MobileScreen>
  );
};

const connected = observer(Mobile);

export { connected as Mobile };
