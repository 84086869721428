import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { notificationsFaq } from 'constants/pages';
import { useStore } from 'hooks/useStore';
import { interpolate } from 'utils/string/format';

import { Trial } from '../Trial';
import { ExpectedRateMessage } from './ExpectedRateMessage';
import { FixedPriceMessage } from './FixedPriceMessage';
import { PaidSubscriptionMessage } from './PaidSubscriptionMessage';
import { SMS } from './SMS';
import { SubscriptionMessage } from './SubscriptionMessage';
import { UnlimitedNotificationsLicenseMessage } from './UnlimitedNotificationsLicenseMessage';

import css from './Prices.module.css';

const Prices = () => {
  const {
    app: { i18n, env },
    savedFilters: { popup },
  } = useStore();

  if (!popup || !popup?.smsParams) return null;

  if (popup.hasFreeSubscription) {
    return (
      <div className={css.container}>
        <SubscriptionMessage>
          <strong>{i18n.subscriptions.freeSubscription}</strong>
          <SMS />
        </SubscriptionMessage>
      </div>
    );
  }

  if (!popup.notificationsRate) return null;

  return (
    <div className={css.container}>
      <Trial mode="short" />

      <UnlimitedNotificationsLicenseMessage />

      <PaidSubscriptionMessage />

      <SMS />

      <ExpectedRateMessage />

      <FixedPriceMessage />

      <a
        className={cn('glz-link glz-is-medium', css.link)}
        href={interpolate(notificationsFaq, { env })}
        rel="noopener noreferrer"
        target="_blank"
      >
        {i18n.filterPopup.moreAbout}
      </a>
    </div>
  );
};

const connected = observer(Prices);

export { connected as Prices };
