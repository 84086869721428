import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { interpolate } from 'utils/string/format';

import css from './SMS.module.css';

const SMS = () => {
  const {
    app: { i18n },
    savedFilters: { popup },
    view,
  } = useStore();

  if (!popup?.smsParams || !popup.channels.sms.value) return null;

  return (
    <p className={cn('glz-p glz-is-size-3', css.sms)}>
      {interpolate(i18n.subscriptions.paidSms, { atis: view.atisCount(popup.smsParams.rate) })}
    </p>
  );
};

const connected = observer(SMS);

export { connected as SMS };
