import Button from '@components/button';
import cn from 'clsx';

import { events } from 'constants/metrics';
import { addTruck } from 'constants/pages';
import { useStore } from 'hooks/useStore';
import { sendMetricsEvent } from 'utils/metrics';
import { interpolate } from 'utils/string/format';

import { IconCargoSearchHistory } from './IconCargoSearchHistory';

import css from './EmptyResults.module.css';

const EmptyResults = ({ isEmpty }: { isEmpty: boolean }) => {
  const { app } = useStore();

  if (!isEmpty) return null;

  return (
    <div className={css.container}>
      <div className={css.descriptionContainer}>
        <IconCargoSearchHistory />

        <p className={cn('glz-h glz-is-size-4', css.title)}>{app.i18n.app.noResults.title}</p>
        <p className={cn('glz-p glz-is-size-1', css.description)}>{app.i18n.app.noResults.description}</p>
      </div>

      <Button
        as="a"
        href={interpolate(addTruck, { env: app.env })}
        rel="noopener noreferrer"
        target="_blank"
        theme="primary"
        onClick={() => sendMetricsEvent(events.addTsFromLoads)}
      >
        {app.i18n.trucks.addTruck}
      </Button>
    </div>
  );
};

export { EmptyResults };
