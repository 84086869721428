import Select from '@components/select';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { Checkbox } from 'components/Checkbox';
import { Field } from 'components/Field';
import { MinMax } from 'components/MinMax';
import { QuestionTooltip } from 'components/QuestionTooltip';
import { RestrictedInput } from 'components/RestrictedInput';
import { inputRestriction } from 'constants/inputRestrictions';
import { useStore } from 'hooks/useStore';

import css from './Content.module.css';

const Content = () => {
  const {
    app: { i18n },
    ui: { isMobile },
    filter: { loadType, length, width, height, withDimensions, pallets },
  } = useStore();

  return (
    <>
      <Checkbox
        checked={withDimensions.value}
        className={css.withoutDimensions}
        small={!isMobile}
        onChange={withDimensions.setValueFromCheckbox}
      >
        {i18n.dimensions.withoutDimensions.label}
        <QuestionTooltip text={i18n.dimensions.withoutDimensions.tip} />
      </Checkbox>

      <Field
        isRow
        containerClassName={css.field}
        contentClassName={css.fieldContent}
        labelClassName={css.fieldLabel}
        labelText={i18n.dimensions.length.label}
      >
        <MinMax
          max={length.max.view}
          min={length.min.view}
          units={i18n.common.meter}
          onBlur={length.handleSwap}
          onMaxChange={length.max.setValueFromInput}
          onMinChange={length.min.setValueFromInput}
        />
      </Field>

      <Field
        isRow
        containerClassName={css.field}
        contentClassName={css.fieldContent}
        labelClassName={css.fieldLabel}
        labelText={i18n.dimensions.width.label}
      >
        <MinMax
          max={width.max.view}
          min={width.min.view}
          units={i18n.common.meter}
          onBlur={width.handleSwap}
          onMaxChange={width.max.setValueFromInput}
          onMinChange={width.min.setValueFromInput}
        />
      </Field>

      <Field
        isRow
        containerClassName={css.field}
        contentClassName={css.fieldContent}
        labelClassName={css.fieldLabel}
        labelText={i18n.dimensions.height.label}
      >
        <MinMax
          max={height.max.view}
          min={height.min.view}
          units={i18n.common.meter}
          onBlur={height.handleSwap}
          onMaxChange={height.max.setValueFromInput}
          onMinChange={height.min.setValueFromInput}
        />
      </Field>

      <Field
        isRow
        containerClassName={css.field}
        contentClassName={cn(css.fieldContent, css.fieldWide)}
        labelClassName={css.fieldLabel}
        labelText={i18n.dimensions.loadTypes.label}
      >
        <Select
          className={css.partialSelect}
          dropdownWidth={{
            min: '100%',
            current: '100%',
          }}
          labelProperty="label"
          options={loadType.options}
          usePortal={false}
          value={loadType.option}
          onChange={loadType.setOption}
        />
      </Field>

      <Field
        isRow
        containerClassName={css.field}
        contentClassName={cn(css.fieldContent, css.fieldWide)}
        labelClassName={css.fieldLabel}
        labelText={i18n.dimensions.palletes.label}
      >
        <RestrictedInput
          after={i18n.common.pieces}
          classNames={{ wrapper: css.palletsInputWrapper }}
          customRestrict={['leadingZero', 'floatNumbers', 'leadingSymbols']}
          inputRestrictionType={inputRestriction.nonNegativeFloatDecimalLimit}
          maxLength={4}
          placeholder={i18n.common.to}
          value={pallets.value}
          onValueChange={pallets.setValueFromInput}
        />
      </Field>
    </>
  );
};

const connected = observer(Content);

export { connected as Content };
