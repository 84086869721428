import Field from '@components/field';
import Input from '@components/input';
import Link from '@components/link';
import Popup from '@components/popup';
import Preloader from '@components/preloader';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, FC } from 'react';

import { Stack } from 'components/Stack';
import { notificationsFaq } from 'constants/pages';
import { useStore } from 'hooks/useStore';
import { BellIcon } from 'layout/TabsContent/Card/MainIcon/BellIcon';
import { interpolate } from 'utils/string/format';

import { CollectivityToggle } from './CollectivityToggle';
import { FilterSummary } from './FilterSummary';
import { Footer } from './Footer';
import { SubsSettings } from './SubsSettings';
import { Prices } from './SubsSettings/Prices';
import { Trial } from './SubsSettings/Trial';
import { Title } from './Title';
import { Warnings } from './Warnings';

import css from './FilterPopup.module.css';

const FilterPopup: FC = () => {
  const {
    ui: { popups },
    app: { i18n, env },
    savedFilters: { popup, closePopup },
  } = useStore();

  if (!popups.filterPopup?.isOpened || !popup) return null;

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    if (popup.isNameInvalid) {
      popup.setIsNameInvalid(false);
    }

    popup.name.setValue(event.target.value);
  };

  return (
    <Popup closeOnEscape closeOnOverlayClick zIndex={{ overlay: 10300 }} onClose={closePopup}>
      {popup.isValidatingFilter ? (
        <div className={css.preloader}>
          <Preloader width={64} />
        </div>
      ) : (
        <>
          <Popup.Header>
            <Title />
          </Popup.Header>

          <Popup.Content>
            <FilterSummary />

            <Field
              className={cn(css.field_fullWidth, css.filterNameField)}
              classNames={{ label: css.filterNameLabel }}
              label={i18n.filterPopup.name}
            >
              <Input
                error={popup.nameErrorDescription}
                maxLength={500}
                value={popup.name.value}
                onChange={handleChangeName}
              />
            </Field>

            <Field className={css.field_fullWidth}>
              <CollectivityToggle />
            </Field>
          </Popup.Content>

          <div className={css.subscribeContainer}>
            <Popup.Content className={css.subscribeContent}>
              <Stack className={css.info} gap={16}>
                <SubsSettings />

                {!popup.hasSubscription.value && <Trial mode="full" />}

                {popup.hasSubscription.value && <Prices />}

                {!popup.hasSubscription.value && (
                  <Link
                    href={interpolate(notificationsFaq, { env })}
                    rel="noopener noreferrer"
                    size="medium"
                    target="_blank"
                  >
                    {i18n.filterPopup.moreAbout}
                  </Link>
                )}
              </Stack>

              {!popup.hasSubscription.value && (
                <div className={css.subscribeIcon}>
                  <BellIcon size="large" theme="outlined" type="success" />
                </div>
              )}
            </Popup.Content>
          </div>

          <Warnings />

          <Popup.Footer className={css.footer}>
            <Footer />
          </Popup.Footer>
        </>
      )}
    </Popup>
  );
};

const connected = observer(FilterPopup);

export { connected as FilterPopup };
