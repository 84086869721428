import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { ElementType, useState } from 'react';

import { DropdownToggle } from 'components/DropdownToggle';
import { useStore } from 'hooks/useStore';

import css from './TypeSelector.module.css';

interface TypeSelectorProps {
  dictionary: object[];
  shortListIds: string[] | number[];
  OptionsSelector: ElementType;
  OptionsList: ElementType;
  initialButtonText?: string;
  selectedValues?: string[];
  selectedCount?: number;
  onChange?: (values: string[], bitsum: string, selectedCount: number) => void;
  onClear?: () => void;
  useShortName?: boolean;
}

const TypeSelector = (props: TypeSelectorProps) => {
  const {
    app: { i18n, locale },
  } = useStore();

  const [active, setActive] = useState(false);

  const handleToggle = () => {
    setActive(!active);
  };

  const {
    dictionary,
    shortListIds,
    OptionsSelector,
    OptionsList,
    initialButtonText,
    selectedValues,
    selectedCount,
    onChange,
    onClear,
    useShortName,
  } = props;

  const buttonText = selectedValues?.length
    ? `${i18n.app.total} ${selectedCount}`
    : initialButtonText || i18n.app.allTypes;

  const handleChange = (values: string[], bitsum: string, selectedCount: number) => {
    onChange?.(values, bitsum, selectedCount);
  };

  const handleClear = () => {
    onClear?.();
  };

  return (
    <div className={css.buttonsContainer}>
      {Boolean(dictionary.length) && (
        <OptionsList
          className={css.selector}
          dictionary={dictionary}
          selectedValues={selectedValues}
          useShortName={useShortName}
          valueProperty="id"
          visibleIds={shortListIds}
          onChange={handleChange}
        />
      )}

      <div className={css.buttons}>
        <DropdownToggle
          active={active}
          capture={true}
          classNames={{
            wrapper: css.triggerWrapper,
          }}
          dropdown={
            <OptionsSelector
              className={css.selector}
              clearAllSelectedValues={handleClear}
              dictionary={dictionary}
              locale={locale}
              selectedValues={selectedValues}
              onChange={handleChange}
            />
          }
          onToggle={handleToggle}
        >
          <DropdownToggle.DefaultButton
            gray
            active={active}
            classNames={{ button: css.toggleButton, icon: css.toggleButtonIcon }}
          >
            <span className={css.buttonText}>{buttonText}</span>
          </DropdownToggle.DefaultButton>
        </DropdownToggle>

        {Boolean(selectedCount) && (
          <button
            className={cn('glz-link glz-is-medium glz-is-pseudo glz-is-gray')}
            type="button"
            onClick={handleClear}
          >
            {i18n.app.reset}
          </button>
        )}
      </div>
    </div>
  );
};

const connected = observer(TypeSelector);

export { connected as TypeSelector };
