import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { DropdownToggle } from 'components/DropdownToggle';
import { useStore } from 'hooks/useStore';
import { ViewFilterTypes } from 'store/TabsDataStore/TabsDataStore';

import css from './FilterByTypeDropdown.module.css';

const FilterByTypeDropdown = () => {
  const {
    app,
    tabsData: { viewFilterType, setViewFilterType },
  } = useStore();
  const [active, setActive] = useState(false);

  const options = [
    { id: ViewFilterTypes.all, label: app.i18n.savedFilters.viewFilterTypes.all },
    { id: ViewFilterTypes.personal, label: app.i18n.savedFilters.viewFilterTypes.personal },
    { id: ViewFilterTypes.collective, label: app.i18n.savedFilters.viewFilterTypes.collective },
  ];

  const handleOptionClick = (option: typeof options[number]) => {
    setViewFilterType(option.id);
    setActive(false);
  };

  return (
    <DropdownToggle
      active={active}
      dropdown={
        <ul className={cn(css.list, 'glz-dropdown-list')}>
          {options.map(option => (
            <li
              key={option.id}
              className={cn(css.item, 'glz-dropdown-list-item')}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      }
      onToggle={() => setActive(!active)}
    >
      <DropdownToggle.DefaultButton active={active}>
        <span className={css.chooseListFirstPart}>{app.i18n.savedFilters.viewFilterTypes[viewFilterType]}</span>
      </DropdownToggle.DefaultButton>
    </DropdownToggle>
  );
};

const connected = observer(FilterByTypeDropdown);

export { connected as FilterByTypeDropdown };
