import Icon from '@components/icon';
import cn from 'clsx';

import { useStore } from 'hooks/useStore';

import css from './Button.module.css';

const Button = () => {
  const {
    app: { i18n },
    filter: { loadDate },
    ui: { toggleIsDateOptionsExpanded, setIsDatePickerOpened },
  } = useStore();

  const handleClick = () => {
    setIsDatePickerOpened(true);
    loadDate.setDateOption('manual');
    toggleIsDateOptionsExpanded();
  };

  return (
    <button className={'glz-link glz-is-dashed'} type="button" onClick={handleClick}>
      <Icon
        className={cn(css.icon, 'glz-before-icon')}
        color="currentColor"
        height={14}
        name="ic_calendar"
        width={14}
      />
      {i18n.dates.exactDateOfLoading}
    </button>
  );
};

export { Button };
