import Autosuggest from '@components/autosuggest';
import Field from '@components/field';
import Select from '@components/select';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { Checkbox } from 'components/Checkbox';
import { DropdownToggle } from 'components/DropdownToggle';
import { useStore } from 'hooks/useStore';
import { TFirmList } from 'store/Dictionaries/Dictionaries';
import { PopupIds } from 'store/UIStore/Popups';
import { interpolate } from 'utils/string/format';

import { FirmEmoji } from '../FirmEmoji';
import { QuickFirmsSearch } from '../QuickFirmsSearch';

import css from './Content.module.css';

enum listGroups {
  blackLists = 'blackLists',
  neutralLists = 'neutralLists',
  whiteLists = 'whiteLists',
}

const Content = () => {
  const {
    app: { i18n },
    ui: { popups, isMobile },
    profile,
    filter: {
      firms: { ratingType, isExclusiveMode, firmGeo, toggleList, selectedLists, isSelectedListsDefault },
    },
    dictionaries: {
      data: { privateFirmLists },
    },
  } = useStore();

  const [active, setActive] = useState(false);

  const handleToggle = () => {
    setActive(!active);
  };

  const openNewFirmListPopup = () => {
    popups.open(PopupIds.firmListsPopup);
  };

  const activeFirmList = isExclusiveMode.option.value
    ? privateFirmLists.favExclusiveLists
    : privateFirmLists.favInclusiveLists;

  const activeDropdownFirmLists = isExclusiveMode.option.value
    ? [listGroups.blackLists, listGroups.neutralLists, listGroups.whiteLists]
    : [listGroups.whiteLists, listGroups.neutralLists, listGroups.blackLists];

  const dropdownButtonText = isSelectedListsDefault
    ? i18n.firms.allLists
    : interpolate(i18n.firms.moreLists, { count: selectedLists.length.toString() });

  return (
    <>
      <div className={css.separator}></div>
      {profile.data?.isPaidUser ? (
        <div className={css.firmsFilter}>
          <div className={css.innerColumn}>
            <Field className={css.field} size="medium">
              <Select
                dropdownWidth={{
                  min: '100%',
                  current: '100%',
                }}
                labelProperty="label"
                options={ratingType.options}
                usePortal={false}
                value={ratingType.option}
                onChange={ratingType.setOption}
              />
            </Field>

            <Field className={css.field} size="medium">
              <Autosuggest
                placeholder={i18n.firms.from}
                suggestions={firmGeo.suggestions}
                value={firmGeo.value}
                onChange={firmGeo.setValue}
                onClear={firmGeo.clear}
                onSuggestionSelected={firmGeo.onSuggestionSelected}
                onSuggestionsClearRequested={firmGeo.resetSuggestions}
                onSuggestionsFetchRequested={firmGeo.onFetchRequested}
              />
            </Field>

            <Field className={css.field} size="medium">
              <QuickFirmsSearch />
            </Field>
          </div>

          <div className={css.innerColumn}>
            <Field className={css.field} size="medium">
              <Select
                dropdownWidth={{
                  min: '100%',
                  current: '100%',
                }}
                labelProperty="label"
                options={isExclusiveMode.options}
                usePortal={false}
                value={isExclusiveMode.option}
                onChange={isExclusiveMode.setOption}
              />
            </Field>

            {privateFirmLists.totalLists > 0 && (
              <ul className={css.firmList}>
                {activeFirmList.map((list: TFirmList) => (
                  <li key={list.id} className={css.listItem}>
                    <Checkbox
                      small
                      checked={selectedLists.includes(list.id)}
                      className={css.container}
                      contentClassName={css.firmListNameContent}
                      disabled={false}
                      onChange={() => toggleList(list.id)}
                    >
                      <span className={css.firmName}>{list.name}</span>
                      {list.emoji && <FirmEmoji className={css.emoji} emojiSource={list.emoji} />}
                      {!list.elements.length && <span>({i18n.firms.emptyList})</span>}
                    </Checkbox>
                  </li>
                ))}
              </ul>
            )}

            <div className={css.toolbar}>
              {privateFirmLists.totalLists > 0 && (
                <DropdownToggle
                  dropdown={
                    <ul className={cn('glz-dropdown-list', css.dropdown)}>
                      {activeDropdownFirmLists.map(listName => {
                        if (privateFirmLists[listName].lists.length <= 0) return null;

                        return (
                          <li key={listName}>
                            <span className={css.firmListName}>{privateFirmLists[listName].name}</span>
                            <ul className={css.firmDropdownList}>
                              {privateFirmLists[listName].lists.map((list: TFirmList) => (
                                <li key={list.id} className={css.listItem}>
                                  <Checkbox
                                    small
                                    checked={selectedLists.includes(list.id)}
                                    className={css.container}
                                    contentClassName={css.firmListNameContent}
                                    disabled={false}
                                    onChange={() => toggleList(list.id)}
                                  >
                                    <span className={css.firmName}>{list.name}</span>
                                    {list.emoji && <FirmEmoji className={css.emoji} emojiSource={list.emoji} />}
                                    {!list.elements.length && <span>({i18n.firms.emptyList})</span>}
                                  </Checkbox>
                                </li>
                              ))}
                            </ul>
                          </li>
                        );
                      })}
                    </ul>
                  }
                  onToggle={handleToggle}
                >
                  <DropdownToggle.DefaultButton active={active} gray={!isMobile}>
                    {dropdownButtonText}
                  </DropdownToggle.DefaultButton>
                </DropdownToggle>
              )}

              <button
                className={cn('glz-link glz-is-pseudo ', {
                  'glz-is-gray': !isMobile,
                })}
                title={i18n.firms.createNewList}
                onClick={openNewFirmListPopup}
              >
                {i18n.firms.createNew}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <p className={cn(css.onlyForPaid, 'glz-p glz-is-size-3 glz-is-bold')}>{i18n.firms.onlyForPaid}</p>
      )}
    </>
  );
};

const connected = observer(Content);

export { connected as Content };
