import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';

import { EditContactPopup } from 'components/EditContactPopup';
import { IExtendedFirmSearchPopup } from 'components/ExtendedFirmSearchPopup';
import { IGeoListsPopup } from 'components/GeoListsPopup';
import { useStore } from 'hooks/useStore';
import { FilterPopup } from 'layout/FilterPopup';
import { PopupIds } from 'store/UIStore/Popups';

import { FilterDeleteConfirmationPopup } from './FilterDeleteConfirmationPopup';
import { FilterUnsubscribeConfirmationPopup } from './FilterUnsubscribeConfirmationPopup';

const GeoListsPopup = dynamic<IGeoListsPopup>(() => import('components/GeoListsPopup').then(mod => mod.default), {
  ssr: false,
});
const OnlyPaidPopup = dynamic<{}>(() => import('components/OnlyPaidPopup').then(mod => mod.default), {
  ssr: false,
});
const FirmListsPopup = dynamic<{}>(() => import('components/FirmListsPopup').then(mod => mod.default), {
  ssr: false,
});
const ExtendedFirmSearchPopup = dynamic<IExtendedFirmSearchPopup>(
  () => import('components/ExtendedFirmSearchPopup').then(mod => mod.default),
  {
    ssr: false,
  },
);

const PopupsContainer = () => {
  const {
    ui: { popups },
  } = useStore();

  return (
    <>
      <FilterPopup />
      {popups[PopupIds.geoListsPopup]?.isOpened && <GeoListsPopup {...popups[PopupIds.geoListsPopup]?.payload} />}
      {popups[PopupIds.onlyPaidPopup]?.isOpened && <OnlyPaidPopup {...popups[PopupIds.onlyPaidPopup]?.payload} />}
      {popups[PopupIds.firmListsPopup]?.isOpened && <FirmListsPopup />}
      {popups[PopupIds.extendedFirmSearchPopup]?.isOpened && (
        <ExtendedFirmSearchPopup {...popups[PopupIds.extendedFirmSearchPopup]?.payload} />
      )}
      {popups[PopupIds.editContactPopup]?.isOpened && <EditContactPopup />}
      <FilterDeleteConfirmationPopup />
      <FilterUnsubscribeConfirmationPopup />
    </>
  );
};

const connected = observer(PopupsContainer);

export { connected as PopupsContainer };
