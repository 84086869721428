import Popup from '@components/popup';
import cn from 'clsx';
import { ReactNode } from 'react';

import css from './Warning.module.css';

interface IWarning {
  children: string | ReactNode;
  className?: string;
}

const Warning = ({ children, className }: IWarning) => {
  return (
    <Popup.Content className={cn(css.warning, className)} theme="feature">
      {children}
    </Popup.Content>
  );
};

export { Warning };
