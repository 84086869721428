import { observer } from 'mobx-react-lite';
import type { FC } from 'react';

import { Pagination } from 'components/Pagination';
import { useStore } from 'hooks/useStore';

const SearchResultsPagination: FC<TSearchResultsPaginationProps> = props => {
  const {
    app: { locale, searchLoads, loading },
    ui,
  } = useStore();

  const handlePageChange = ({ selected }: { selected: number }) => {
    if (loading.search) return;

    ui.pagination.setCurrentPage(selected);
    searchLoads({ isNewSearch: false });
  };

  return (
    <Pagination
      className={props.className}
      disabled={false}
      initialPage={ui.pagination.currentPage}
      locale={locale}
      pageCount={ui.pagination.totalPages}
      tooltip={props.tooltip}
      onBlur={() => ui.setShouldSearchByEnter(true)}
      onFocus={() => ui.setShouldSearchByEnter(false)}
      onPageChange={handlePageChange}
    />
  );
};

export type TSearchResultsPaginationProps = {
  tooltip?: boolean;
  className?: string;
};

const connected = observer(SearchResultsPagination);

export { connected as SearchResultsPagination };
