import getEnvironDomain from 'ati-utils/getEnvironDomain';

export const parsePostMessage = (event: MessageEvent) => {
  const domain = getEnvironDomain();
  const atiUrl = `https://loads.${domain}`;
  const loadsUrl = `https://${domain}`;
  const isAtiOrigin = event.origin === atiUrl || event.origin === loadsUrl;

  if (!event || !event.data || !isAtiOrigin) return;

  try {
    const parsedEventData = JSON.parse(event.data);

    return parsedEventData;
  } catch (error) {
    return;
  }
};
