import Select from '@components/select';
import { OptionType } from '@components/select/dist/types';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useStore } from 'hooks/useStore';
import { SiteFeaturesIDs } from 'store/SiteFeatures/SiteFeatures';
import { SelectInputRenderData } from 'types/@components/common';

import css from './ADR.module.css';

const DISPLAYABLE_ADR_COUNT = 3;
const DROPDOWN_WIDTH = 162;
const INPUT_WIDTH = 137;

const renderInputValue = (data: SelectInputRenderData, selectedArray: OptionType[]) => {
  if (selectedArray.length) {
    const selectedADRs = selectedArray
      .slice(0, DISPLAYABLE_ADR_COUNT)
      .map(adr => adr.value)
      .sort();

    const counter = selectedArray.length - DISPLAYABLE_ADR_COUNT;

    return (
      <>
        <span className={css.inputValue}>ADR {selectedADRs.join(',')}</span>
        {counter > 0 && <span className={css.inputValue}>+{counter}</span>}
      </>
    );
  }

  return <span className={css.inputValue}>{data.placeholder}</span>;
};

const renderSuggestion = (suggestion: OptionType) => (
  <div className={css.suggestion}>
    <span>{suggestion.label}</span>
    <span className={css.suggestionDescription}>{suggestion.description}</span>
  </div>
);

const ADR = () => {
  const {
    app: { i18n, locale },
    options: { getADRTypes },
    siteFeatures,
    filter: {
      extraParams: { ADR, withADR },
    },
  } = useStore();

  if (!withADR || !siteFeatures.isSiteFeatureEnabled[SiteFeaturesIDs.extendedADR]) return null;

  return (
    <div className={css.ADR} style={{ maxWidth: INPUT_WIDTH }}>
      <Select
        isMulti
        classNames={{
          inputWrapper: css.inputWrapper,
        }}
        dropdownPositionOffset={{
          x: INPUT_WIDTH - DROPDOWN_WIDTH,
        }}
        dropdownWidth={{
          min: DROPDOWN_WIDTH,
          max: DROPDOWN_WIDTH,
        }}
        labelProperty="label"
        locale={locale}
        multiValue={ADR.selectedOptions}
        options={getADRTypes()}
        placeholder={i18n.ADR.any}
        renderInputValue={(data: SelectInputRenderData) => renderInputValue(data, ADR.selectedOptions)}
        renderSuggestion={renderSuggestion}
        strings={{
          [locale]: {
            removeAll: i18n.ADR.any,
          },
        }}
        usePortal={false}
        valueProperty="value"
        onMultiChange={ADR.selectOption}
      />
    </div>
  );
};

const connected = observer(ADR);

export { connected as ADR };
