import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { DropdownToggle } from 'components/DropdownToggle';
import { useStore } from 'hooks/useStore';
import { Button as ManualButton } from 'layout/Search/Filters/Dates/Manual/Button';

import { List } from './List';

import css from './Options.module.css';

const Options = () => {
  const {
    filter: { loadDate },
    ui: { isDateOptionsExpanded, toggleIsDateOptionsExpanded },
  } = useStore();

  const dates = loadDate.dateOptions;
  const selectedDate = loadDate.selectedDate;

  const handleClick = () => {
    toggleIsDateOptionsExpanded();
  };

  return (
    <div className={css.container}>
      <DropdownToggle
        active={isDateOptionsExpanded}
        additionalAction={<ManualButton />}
        classNames={{
          wrapper: css.dropdownWrapper,
          trigger: cn(css.input, { [css.input_expanded]: isDateOptionsExpanded }),
          additionalAction: css.additionalAction,
        }}
        dropdown={<List dates={dates as DateOption[]} />}
        onToggle={handleClick}
      >
        <span className={css.inputCaption}>{selectedDate?.caption}</span>
      </DropdownToggle>
    </div>
  );
};

const connected = observer(Options);

export { connected as Options };
