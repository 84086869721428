import DatePicker from '@components/date-picker';
import cn from 'clsx';
import { format, isAfter, startOfToday } from 'date-fns';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';

import css from './Manual.module.css';

const currentDate = startOfToday();

const Manual = () => {
  const {
    app: { i18n, locale },
    filter: {
      loadDate: { isManualDateActive, setFromDate, setToDate, fromDate, toDate, setDateOption },
    },
    ui: { setIsDatePickerOpened, isDatePickerOpened },
  } = useStore();

  const handleFromDateChange = (date: Date | null) => {
    if (date && toDate && isAfter(date.setHours(0, 0, 0, 0), toDate)) {
      setToDate(null);
    }
    setFromDate(date);
  };

  const handleToDateChange = (date: Date | null) => {
    setToDate(date);
  };

  const handleClose = (isVisible: Boolean) => {
    if (!isVisible) setIsDatePickerOpened(isVisible);
  };

  const handleClick = () => {
    setIsDatePickerOpened(true);
    setDateOption('manual');
  };

  const renderDatePicker = () => {
    if (isManualDateActive) {
      return (
        <>
          <span className={css.label}>{i18n.dates.from}</span>
          <DatePicker
            className={css.datepickerInput}
            classNames={{
              inputMask: css.datepickerInput,
            }}
            externalDateInput={fromDate ? format(fromDate, 'dd.MM.yyyy') : ''}
            isClearable={false}
            isOpenByDefault={isDatePickerOpened}
            locale={locale}
            minDate={currentDate}
            placeholder={i18n.dates.placeholder}
            value={fromDate}
            onCalendarVisibilityChange={handleClose}
            onChange={handleFromDateChange}
          />

          <span className={css.label}>{i18n.dates.to}</span>
          <DatePicker
            className={css.datepickerInput}
            classNames={{
              inputMask: css.datepickerInput,
            }}
            externalDateInput={toDate ? format(toDate, 'dd.MM.yyyy') : ''}
            isClearable={true}
            locale={locale}
            minDate={fromDate || currentDate}
            placeholder={i18n.dates.placeholder}
            value={toDate}
            onChange={handleToDateChange}
          />
        </>
      );
    }

    return (
      <button className={cn(css.button, 'glz-link')} type="button" onClick={handleClick}>
        {i18n.dates.exactDate}
      </button>
    );
  };

  return <div className={css.container}>{renderDatePicker()}</div>;
};

const connected = observer(Manual);

export { connected as Manual };
