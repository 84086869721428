import { observer } from 'mobx-react-lite';

import { SubscriptionMessage } from '../SubscriptionMessage';
import { ExpectedNotificationsCountText } from './ExpectedNotificationsCountText';
import { ExpectedNotificationsRateText } from './ExpectedNotificationsRateText';

const ExpectedRateMessage = () => {
  return (
    <SubscriptionMessage>
      <ExpectedNotificationsCountText />
      <br />
      <ExpectedNotificationsRateText />
    </SubscriptionMessage>
  );
};

const connected = observer(ExpectedRateMessage);

export { connected as ExpectedRateMessage };
