import { observer } from 'mobx-react-lite';
import React from 'react';

import { emojiFiles } from 'constants/pages';
import { useStore } from 'hooks/useStore';
import { interpolate } from 'utils/string/format';

type FirmEmojiProps = {
  emojiSource: string;
  className?: string;
};

function FirmEmoji({ emojiSource, className }: FirmEmojiProps) {
  const {
    app: { env },
  } = useStore();

  const emoji = emojiSource?.replace(/^:|:$/gi, '');
  const emojiUrl = interpolate(emojiFiles, { env, emoji });

  return (
    <span aria-label={emoji}>
      <span
        className={className}
        style={{
          display: 'inline-block',
          width: 15,
          height: 15,
          backgroundImage: `url(${emojiUrl})`,
          backgroundSize: 'contain',
        }}
      />
    </span>
  );
}

const connected = observer(FirmEmoji);

export { connected as FirmEmoji };
