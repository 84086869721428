import QuickFirmsSearchUI from '@components/quick-firm-search';
import type { Suggestions } from '@components/quick-firm-search/dist/types';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { PopupIds } from 'store/UIStore/Popups';
import { parsePostMessage } from 'utils/parsePostMessage';

const defaultSuggestions = {
  data: [],
  meta: {},
  labels: {},
} as unknown as Suggestions;

const i18nMap = {
  nameInSnakeCase: {
    ru: 'name_rus',
    en: 'name_eng',
  },
  nameInCamelCase: {
    ru: 'nameRus',
    en: 'nameEng',
  },
};

enum EventType {
  selectExtendedSearchPopup = 'selectExtendedSearchPopup',
  closeExtendedSearchPopup = 'closeExtendedSearchPopup',
}

const QuickFirmsSearch = () => {
  const {
    app: { i18n, env, uiComponentsLocale },
    ui: { popups },
    filter: {
      firms: { firmName },
    },
  } = useStore();

  const suggestions = Array.isArray(firmName.suggestions) ? defaultSuggestions : firmName.suggestions;

  const handleFirmNameFetchRequested = () => {
    if (firmName.value.length) {
      firmName.onFetchRequested();
    }
  };

  const extendedFirmSearchListener = (event: MessageEvent) => {
    const parsedEvent = parsePostMessage(event);

    if (!parsedEvent || !parsedEvent.eventType) return;

    if (parsedEvent.eventType === EventType.selectExtendedSearchPopup) {
      firmName.onSuggestionSelected(undefined, { suggestion: { alias_id: parsedEvent.data.aliasId } });
      firmName.setValue(parsedEvent.data[i18nMap.nameInCamelCase[uiComponentsLocale]]);
      return popups.close(PopupIds.extendedFirmSearchPopup);
    }

    if (parsedEvent.eventType === EventType.closeExtendedSearchPopup) {
      window.removeEventListener('message', extendedFirmSearchListener, false);
      return popups.close(PopupIds.extendedFirmSearchPopup);
    }
  };

  const handleOpenExtendedSearch = () => {
    firmName.resetSuggestions();
    popups.open(PopupIds.extendedFirmSearchPopup, { env, title: i18n.firms.extendedSearch });
    window.addEventListener('message', extendedFirmSearchListener, false);
  };

  return (
    <QuickFirmsSearchUI
      placeholder={i18n.firms.findFirm}
      searchProperty="name"
      suggestions={suggestions}
      /* @ts-ignore */
      value={firmName.value}
      valueProperty="name"
      onChange={firmName.setValue}
      onClear={firmName.clear}
      onExtendedSearchOpen={handleOpenExtendedSearch}
      onSuggestionSelected={firmName.onSuggestionSelected}
      onSuggestionsClearRequested={firmName.resetSuggestions}
      onSuggestionsFetchRequested={handleFirmNameFetchRequested}
    />
  );
};

const connected = observer(QuickFirmsSearch);

export { connected as QuickFirmsSearch };
