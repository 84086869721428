import { observer } from 'mobx-react-lite';

import { ConfirmationPopup } from 'components/ConfirmationPopup';
import { useStore } from 'hooks/useStore';
import { PopupIds } from 'store/UIStore/Popups';

import css from './FilterDeleteConfirmationPopup.module.css';

const FilterDeleteConfirmationPopup = () => {
  const {
    app: { i18n, loading },
    ui: { popups },
  } = useStore();

  if (!popups.filterDeleteConfirmationPopup?.isOpened) return null;

  const isDeleting = loading.deleteSavedFilter || loading.deleteLastSearchRequest;
  const { title, buttons } = i18n.savedFilters.newDeleteFilterPopup;

  const handleClose = () => {
    popups.close(PopupIds.filterDeleteConfirmationPopup);
  };

  return (
    <ConfirmationPopup
      buttonText={buttons.confirm}
      cancelText={buttons.cancel}
      className={css.confirmationPopup}
      isPopupActionLoading={isDeleting}
      title={title}
      onCancel={handleClose}
      onClose={handleClose}
      onConfirm={popups.filterDeleteConfirmationPopup.payload.onConfirm}
    />
  );
};

const connected = observer(FilterDeleteConfirmationPopup);

export { connected as FilterDeleteConfirmationPopup };
