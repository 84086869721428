import Skeleton from '@components/skeleton';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';

import { GroupOperations } from 'components/GroupOperations';
import { ScrollTopButton } from 'components/ScrollTopButton';
import { useStore } from 'hooks/useStore';
import { AsideMedia } from 'layout/AsideMedia';
import { MobileScreens } from 'layout/MobileScreens';
import { PopupsContainer } from 'layout/PopupsContainer';
import { Search } from 'layout/Search';
import { PopularDestinations } from 'layout/Search/PopularDestinations';
import css from 'styles/main.module.css';

/**
 * делаем так, только ради того, чтобы рендер был на клиенте
 * иначе происходит расхождение между отрендереным на сервере и тем,
 * что рендерится после гидрации, потому что информацию о лицензиях мы получаем на клиенте
 */
const Media = dynamic(() => import('layout/Media').then(mod => mod.default), {
  loading: () => <Skeleton className={css.mediaSkeleton} height={700} />,
  ssr: false,
});

const Home = () => {
  const {
    app,
    profile: { hasWithoutAdsLicence },
  } = useStore();

  return (
    <div className={css.layout}>
      <main className={css.main}>
        <div className={css.search}>
          <Search />
        </div>

        <div className={css.media}>
          <Media />
          <PopularDestinations />
        </div>

        <div className={cn(css.panel, 'glz-z-above-datepicker')}>
          <GroupOperations />
          <ScrollTopButton />
        </div>
      </main>

      {!hasWithoutAdsLicence && (
        <aside className={css.asideMedia}>
          <AsideMedia locale={app.locale} />
        </aside>
      )}

      <PopupsContainer />
      <MobileScreens />
    </div>
  );
};

const connected = observer(Home);

export { connected as default };
